/**
 * @method stringReplace
 * @memberof replace
 * @description Replace string with replacements using regex to match. String.prototype.replace
 * accepts a recursive function as second parameter. In this case, the replacer function performs
 * an Array.prototype.shift on the replacements that are passed in.
 * @param {String} str - Input string to perform replace operation on
 * @param {RegExp} regex - Regex to use for search
 * @param {Array} replacements - Array of values that will be used to replace, for each instance
 * of regex match found
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
 * @return {string} A string with replaced values
 * @example stringReplace('Directions to {0}, {1}, {2}', /\{\d\}/ig, ['123 Main St', 'New York, NY', '10001'])
 */
export function stringReplace(str, regex, replacements) {
    if (typeof str !== 'string') {
        throw TypeError('Input is not of type string');
    }
    if (str.match(regex) && replacements.length !== str.match(regex).length) {
        throw Error('Not enough replacement values');
    }

    const replacerFn = () => replacements.shift();
    return str.replace(regex, replacerFn);
}

/**
 * Replace tokens in a string with the given replacements.
 * Tokens are in the format of {0}, {1}, etc.
 *  - If replacements is an array, the tokens will be replaced in order
 *  - If replacements is an object, the tokens will be replaced by key
 *  - If replacements is not an array or object, the string will be returned as is
 * @param {String} str  The string to replace tokens in
 * @param {Object|Array} replacements The replacements to use
 * @returns
 */
export function tokenReplace(str, replacements) {
    if (typeof replacements === 'object') {
        return Object.keys(replacements).reduce(
            (prev, replacementKey) =>
                stringReplace(prev, new RegExp(`\\{${replacementKey}\\}`, 'g'), [
                    replacements[replacementKey],
                ]),
            str,
        );
    }

    return str;
}
