import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Accordion } from '@/design-system/atoms/Accordion';
import { Button } from '@/design-system/atoms/Button';
import { RadioGroup } from '@/design-system/atoms/Forms/RadioGroup';
import { Radio } from '@/design-system/atoms/Forms/RadioGroup/Radio/Radio';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { useSearchParams } from '@/hooks/useSearchParams';

import { useProviderFilterContext } from '../../../contexts/ProviderFilterContext';
import { useProviderSearchContext } from '../../../contexts/ProviderSearchContext';
import { useProviderUIContext } from '../../../contexts/ProviderUIContext';
import styles from '../ProviderFilter.module.scss';

const ProviderFilterMobile = ({
    accordionItems,
    sortValue,
    sortCriteria,
    handleSort,
    distance,
    setDistance,
    disabledDistanceOptions,
    handleSingleFilter,
    handleResetAll,
    disableNextAvailableSort,
}) => {
    const { openPanel, setOpenPanel } = useProviderFilterContext();

    const {
        coveo: {
            controller: { breadcrumbManager },
        },
        handleMultipleFilters,
        setCoveoAnalytics,
    } = useProviderSearchContext();

    const [state, setState] = useState();
    useEffect(() => {
        return breadcrumbManager.subscribe(() => setState(breadcrumbManager.state));
    }, [breadcrumbManager]);

    const history = useHistory();
    const searchParams = useSearchParams();
    const initialSearchParamsRef = React.useRef();

    React.useEffect(() => {
        if (openPanel === false) {
            return;
        }

        initialSearchParamsRef.current = searchParams.toString();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPanel]);

    const { resetAllLabel, resetAllAriaLabel, seeResultsLabel, seeResultsAriaLabel } =
        useProviderUIContext();

    const filterFooterRef = useRef();
    const [footerPadding, setFooterPadding] = useState(0);

    useEffect(() => {
        const footerHeight = filterFooterRef.current?.getBoundingClientRect().height;
        setFooterPadding(footerHeight);
    }, []);

    const analyticsObj = useMemo(
        () => ({
            componentName: 'Filter Sort Panel',
            componentTitle: 'filter_panel',
            allFilters: searchParams,
            interactionType: 'filter_reset',
        }),
        [searchParams],
    );

    const filteredSortCriteria = useMemo(() => {
        return sortCriteria
            .map(([name]) => {
                if (
                    (name === 'Distance' && disabledDistanceOptions) ||
                    (name === 'Next Available' && disableNextAvailableSort)
                ) {
                    return null;
                }
                return name;
            })
            .filter(Boolean);
    }, [sortCriteria, disabledDistanceOptions, disableNextAvailableSort]);

    /**
     * Reset a single filter
     * @param {string} facetId - The facet id to reset
     * @param {string} value - The value to reset
     * @returns {void}
     */
    const handleResetFilter = useCallback(
        (facetId, facetValue) => {
            if (!facetId || !facetValue) {
                console.error('ProviderFacetRoot - invalid facetValue');
                return;
            }

            setCoveoAnalytics('facetBreadcrumb', { facetId: facetId, facetValue: facetValue });

            handleMultipleFilters(
                {
                    [facetId]: `${facetValue.value};${facetValue.numberOfResults}`,
                },
                {
                    ...analyticsObj,
                    actionLabel: facetValue.value,
                },
            );
        },
        [handleMultipleFilters, analyticsObj, setCoveoAnalytics],
    );

    const handleDistanceChange = useCallback(
        (value) => {
            setDistance(value);

            setCoveoAnalytics(
                value ? 'staticFilterSelect' : 'staticFilterClearAll',
                value
                    ? {
                          staticFilterId: 'distance',
                          staticFilterValue: {
                              caption: `Within ${value} miles`,
                              expression: `distance=${value}`,
                          },
                      }
                    : { staticFilterId: 'distance' },
            );

            handleSingleFilter(
                { distance: value },
                {
                    componentTitle: 'Distance',
                    actionLabel: value ? `Within ${value} miles` : 'Any',
                },
            );
        },
        [handleSingleFilter, setDistance, setCoveoAnalytics],
    );

    return (
        <form
            className={classNames(
                styles[`provider-filter`],
                openPanel && styles[`provider-filter--open`],
            )}
            {...(!openPanel ? { inert: '' } : {})}
        >
            <fieldset
                className={classNames(
                    styles[`provider-filter__fieldset`],
                    styles[`provider-filter__wrapper`],
                )}
            >
                <span className={styles[`provider-filter__intro`]}>
                    <legend className={classNames(styles[`provider-filter__headline`], 'type-h4')}>
                        Filter & Sort
                    </legend>
                    <Button
                        ariaLabel="Close Filter & Sort"
                        buttonStyle={Button.STYLE.TERTIARY}
                        iconComponent={LazyIconX}
                        className={styles[`provider-filter__close`]}
                        type="button"
                        onClick={() => {
                            const path = window.location.pathname;
                            const newUrl = `${path}?${initialSearchParamsRef.current}`;
                            history.push(newUrl);

                            setOpenPanel(false);
                        }}
                    />
                </span>

                {state?.hasBreadcrumbs && (
                    <div className={styles[`provider-filter__active-filters`]}>
                        <div className={styles['provider-filter__active-filters--list']}>
                            {state?.facetBreadcrumbs?.flatMap((filter) =>
                                filter.values
                                    .filter((item) => item.value.state === 'selected')
                                    .map((item) => (
                                        <Button
                                            key={item.value.value}
                                            label={item.value.value}
                                            ariaLabel={`Remove ${item.value.value} filter`}
                                            onClick={() =>
                                                handleResetFilter(filter.facetId, item.value)
                                            }
                                            buttonStyle={Button.STYLE.FILTER}
                                            iconComponent={LazyIconX}
                                            iconSize="large"
                                            type="button"
                                        />
                                    )),
                            )}
                        </div>
                    </div>
                )}

                {filteredSortCriteria.length > 1 && (
                    <fieldset className={styles[`provider-filter__fieldset`]}>
                        <legend
                            className={classNames(
                                styles[`provider-filter__group-legend`],
                                'type-e1',
                            )}
                        >
                            Sort By:
                        </legend>
                        <RadioGroup
                            className={styles[`provider-filter__radio-group`]}
                            value={sortValue}
                            onChange={handleSort}
                        >
                            {filteredSortCriteria.map((name) => (
                                <Radio key={name} value={name} label={name} />
                            ))}
                        </RadioGroup>
                    </fieldset>
                )}
                <fieldset className={styles[`provider-filter__fieldset`]}>
                    <legend
                        className={classNames(
                            styles[`provider-filter__group-legend`],
                            styles[`provider-filter__container`],
                            'type-e1',
                        )}
                    >
                        Filter By:
                    </legend>
                    <div className={styles[`provider-filter__distance-container`]}>
                        <RadioGroup
                            label="Distance"
                            labelClass={styles[`provider-filter__radio-group-label`]}
                            value={distance}
                            onChange={(value) => handleDistanceChange(value)}
                        >
                            <Radio value="" label="Any" />
                            {!disabledDistanceOptions && (
                                <>
                                    <Radio value="2" label="Within 2 miles" />
                                    <Radio value="5" label="Within 5 miles" />
                                    <Radio value="10" label="Within 10 miles" />
                                    <Radio value="20" label="Within 20 miles" />
                                </>
                            )}
                        </RadioGroup>
                    </div>
                </fieldset>
                <Accordion
                    className={styles[`provider-filter__accordion`]}
                    accordionItems={accordionItems}
                    style={{ paddingBottom: `${footerPadding}px` }}
                    noScrollLogic
                />
            </fieldset>
            <div className={styles[`provider-filter__footer`]} ref={filterFooterRef}>
                <Button
                    buttonStyle={Button.STYLE.SECONDARY}
                    className={styles[`provider-filter__reset`]}
                    type="button"
                    label={resetAllLabel}
                    ariaLabel={resetAllAriaLabel}
                    onClick={() => {
                        setOpenPanel(false);
                        handleResetAll();
                    }}
                    analytics={{
                        componentName: 'Filter Sort Panel',
                        componentTitle: 'filter_panel',
                        interactionType: 'filter_reset',
                        allFilters: searchParams,
                    }}
                />
                <Button
                    buttonStyle={Button.STYLE.PRIMARY}
                    className={styles[`location-filter__apply`]}
                    type="button"
                    label={seeResultsLabel}
                    ariaLabel={seeResultsAriaLabel}
                    onClick={() => {
                        setOpenPanel(false);
                    }}
                />
            </div>
        </form>
    );
};

ProviderFilterMobile.propTypes = {
    accordionItems: PropTypes.array,
    sortValue: PropTypes.string,
    sortCriteria: PropTypes.array,
    handleSort: PropTypes.func,
    distance: PropTypes.string,
    setDistance: PropTypes.func,
    disabledDistanceOptions: PropTypes.bool,
    handleSingleFilter: PropTypes.func,
    handleResetAll: PropTypes.func,
    disableNextAvailableSort: PropTypes.bool,
};

export default ProviderFilterMobile;
