import { useListState } from '@react-stately/list';
import { useControlledState } from '@react-stately/utils';

export const useMultiSelectListState = (props) => {
    const {
        defaultSelectedKeys = [],
        selectedKeys: selectedKeysProp,
        onSelectionChange,
        singleSelectEnabled,
    } = props;

    const [selectedKeys, setSelectedKeys] = useControlledState(
        selectedKeysProp,
        defaultSelectedKeys,
        onSelectionChange,
    );

    const { collection, disabledKeys, selectionManager } = useListState({
        ...props,
        allowDuplicateSelectionEvents: true,
        selectionMode: singleSelectEnabled ? 'single' : 'multiple',
        selectedKeys,
        onSelectionChange: (keys) => {
            if (props.onSelectionChange) {
                props.onSelectionChange(keys);
            }

            setSelectedKeys(keys);
        },
    });

    const selectedItems =
        !singleSelectEnabled && selectedKeys === 'all'
            ? [...collection]
            : [...(selectedKeys || [])].map((key) => collection.getItem(key));

    return {
        collection,
        disabledKeys,
        selectionManager,
        selectedKeys,
        setSelectedKeys: setSelectedKeys.bind(selectionManager),
        selectedItems,
    };
};
