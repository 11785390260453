import {
    buildBreadcrumbManager,
    buildDidYouMean,
    buildFacet,
    buildFieldSortCriterion,
    buildQuerySummary,
    buildRelevanceSortCriterion,
    buildResultList,
    buildSearchBox,
    buildSort,
    loadAdvancedSearchQueryActions,
    loadContextActions,
    loadFacetSetActions,
    loadQueryActions,
    loadSearchActions,
    loadSearchAnalyticsActions,
    loadSortCriteriaActions,
    SortOrder,
} from '@coveo/headless';
import { useGeolocationApi, useGeolocationState } from 'contexts/GeolocationContext';
import { getHeadlessEngine } from 'libs/coveo/getHeadlessEngine';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSearchParams } from '@/hooks/useSearchParams';
import { trackInteraction } from '@/utils/analytics';

import { useProviderSetStateContext } from './ProviderStateContext';

const defaultValue = {
    coveo: {
        engine: null,
        controller: {
            searchBox: null,
            querySummary: null,
            resultList: null,
            sort: null,
        },
        action: {
            updateQuery: null,
            executeSearch: null,
        },
    },
    sortCriteria: null,
    patientType: 'new',
    handleScheduling: () => {},
    handleSubmit: () => {},
    handleSingleFilter: () => {},
    handleMultipleFilters: () => {},
    handleFilterResetAll: () => {},
    triggerExecuteSearch: () => {},
    noResultsComponent: null,
    errorComponent: null,
    enableTypeahead: true,
    disableCalendar: false,
    disableNextAvailableSort: false,
    searchboxId: null,
};

export const sortCriteria = [
    ['Relevancy', [buildRelevanceSortCriterion()]],
    [
        'Next Available',
        [
            buildFieldSortCriterion('affiliationrank', SortOrder.Ascending),
            buildFieldSortCriterion('onlinebookingavailable', SortOrder.Descending),
            buildFieldSortCriterion('nextavailabilitydate', SortOrder.Ascending),
            buildFieldSortCriterion('mydistance', SortOrder.Ascending),
            buildFieldSortCriterion('rowid', SortOrder.Descending),
        ],
    ],
];

export const mapUrlParamsToQuery = {
    term: (v) => v,
};

export const mapUrlParamsToAdvancedQuery = {
    new: (v) => (v === 'true' ? '@acceptingnewpatients = True' : ''),
    schedule: (v) => (v === 'true' ? '@onlinebookingavailable = True' : ''),
    cet: (v) => (v ? `@affiliationrank = ${v}` : ''),
};

export const mapUrlParamsToQueryDistance = {
    distance: (v) => (v && v > 0 ? `@mydistanceinmiles <= ${v}` : ''),
};

export const mapUrlParamsToQueryZipCity = {
    'zip-code': (v) => (v ? `@locationzip == ${v}` : ''),
    city: (v) => {
        const arr = v?.split(',');
        return v ? `@locationcity == "${arr[0]}" AND @locationstate == ${arr[arr.length - 1]}` : '';
    },
};

export const mapContextToQueryZipCity = {
    'zip-code': (v) => (v ? [{ contextKey: 'zip', contextValue: v }] : []),
    city: (v) => {
        const arr = v?.split(',');
        return v
            ? [
                  { contextKey: 'city', contextValue: arr[0] },
                  { contextKey: 'state', contextValue: arr[arr.length - 1] },
              ]
            : [];
    },
};

export const mapUrlParamsToSort = {
    sort: (v) => sortCriteria.find(([name]) => name === v)?.[1] ?? sortCriteria[0][1],
};

export const urlParamFilterNames = [
    'specialty',
    'insurance',
    'language',
    'gender',
    'type',
    'visit',
    'affiliation',
    'specialtyservice',
];

export const allUrlParamNames = [
    ...Object.keys(mapUrlParamsToQuery),
    ...Object.keys(mapUrlParamsToAdvancedQuery),
    ...Object.keys(mapUrlParamsToQueryDistance),
    ...Object.keys(mapUrlParamsToQueryZipCity),
    ...Object.keys(mapUrlParamsToSort),
    ...urlParamFilterNames,
];

const removeUrlParams = (searchParams, exclude = []) => {
    const newSearchParams = new URLSearchParams(searchParams);

    const excludesEntries = [];
    for (const name of exclude) {
        const value = newSearchParams.getAll(name).filter(Boolean);
        excludesEntries.push([name, value]);
    }
    for (const name of allUrlParamNames) {
        newSearchParams.delete(name);
    }

    for (const [name, value] of excludesEntries) {
        newSearchParams.append(name, value);
    }

    return newSearchParams;
};

let geolocation = null;

const ProviderSearchContext = createContext(defaultValue);
const ProviderSearchZipCityContext = createContext({ zipCode: '', city: '' });
const ProviderSearchZipCodeErrorContext = createContext(false);

export const ProviderSearchProvider = (props) => {
    const {
        id,
        children,
        coveo,
        noResultsComponent,
        errorComponent,
        enableTypeahead,
        disableCalendar,
        disableNextAvailableSort,
    } = props;

    const [zipCodeServerError, setZipCodeServerError] = useState(false);

    const history = useHistory();
    const searchParams = useSearchParams();

    const setState = useProviderSetStateContext();

    const { zipCode: geoZipCode, city: geoCity } = useGeolocationState() ?? {};
    const [zipCode, setZipCode] = useState(searchParams.get('zip-code') || geoZipCode);
    const [city, setCity] = useState(searchParams.get('city') || geoCity);

    const patientType = searchParams.get('patient-type') || 'new';

    const { setGeolocation } = useGeolocationApi();

    const [queryCorrected, setQueryCorrected] = useState(false);

    const coveoAnalyticsRef = useRef({ event: '', payload: {}, shouldReset: true });
    const engine = useMemo(() => {
        if (!coveo) {
            return null;
        }

        return getHeadlessEngine(`provider-search-${id}`, {
            ...coveo,
            search: { searchHub: 'FindaDoctor' },
            preprocessRequest: (request, _clientOrigin, metadata) => {
                if (!metadata || metadata.method !== 'search' || geolocation == null) {
                    return request;
                }

                const body = JSON.parse(request.body);

                body.queryFunctions = [
                    {
                        fieldName: '@mydistance',
                        function: `min(dist(@loc1latitude, @loc1longitude, ${
                            geolocation.lat ?? 0
                        }, ${geolocation.lng ?? 0}),dist(@loc2latitude, @loc2longitude, ${
                            geolocation.lat ?? 0
                        }, ${geolocation.lng ?? 0}))`,
                    },
                    {
                        fieldName: '@mydistanceinmiles',
                        function: '@mydistance*0.000621371',
                    },
                ];

                body.rankingFunctions = [
                    {
                        expression: '100/@mydistanceinmiles',
                        modifier: 500,
                        normalizeWeight: true,
                    },
                ];

                request.body = JSON.stringify(body);

                return request;
            },
            analytics: { analyticsMode: 'legacy' },
        });
    }, [coveo, id]);

    const value = useMemo(() => {
        if (!engine) {
            return defaultValue;
        }
        const searchboxId = `provider-searchbox-${id}`;

        const QueryActionCreators = loadQueryActions(engine);
        const AdvancedSearchActionCreators = loadAdvancedSearchQueryActions(engine);
        const SortCriteriaActions = loadSortCriteriaActions(engine);
        const FacetSetActions = loadFacetSetActions(engine);
        const SearchAnalyticsActionCreators = loadSearchAnalyticsActions(engine);
        const SearchActionCreators = loadSearchActions(engine);
        const ContextActionCreators = loadContextActions(engine);

        const controllerSearchBox = enableTypeahead
            ? buildSearchBox(engine, {
                  options: { numberOfSuggestions: 10, id: `provider-searchbox-${id}` },
              })
            : null;
        const controllerQuerySummary = buildQuerySummary(engine);
        const controllerDidYouMean = buildDidYouMean(engine, {
            options: {
                automaticallyCorrectQuery: false,
            },
        });
        const controllerResultList = buildResultList(engine, {
            options: {
                fieldsToInclude: [
                    'affiliationrank',
                    'entitytype',
                    'permanentid',
                    'filetype',
                    'affiliation',
                    'location',
                    'overallrating',
                    'body',
                    'abouttheprovider',
                    'degreesname',
                    'firstname',
                    'fullname',
                    'gender',
                    'hospitalaffiliations',
                    'specialties',
                    'acceptingnewpatients',
                    'conditions',
                    'primaryspecialty',
                    'imageurl',
                    'insuranceaccepted',
                    'language',
                    'lastname',
                    'locationcitystate',
                    'locationcity',
                    'locationstate',
                    'locationzip',
                    'locationphone',
                    'latitude',
                    'longitude',
                    'providertype',
                    'additionalvisittypes',
                    'languages',
                    'onlinebookingavailable',
                    'nextavailabilitydate',
                    'providernpi',
                    'practicegroups',
                    'mycharturl',
                    'locepicdepartmentid',
                ],
            },
        });

        const initialCriterion = sortCriteria[0][1];
        const controllerSort = buildSort(engine, {
            initialState: { criterion: initialCriterion },
        });

        /**
         * Factory function to create a facet controller
         * @param {string} facetId - The id of the facet
         * @param {string} field - The field to facet on
         * @param {string} resultsMustMatch - Whether ALL (allValues) or ANY (atLeastOneValue) values must match (default: 'allValues')
         * @returns
         */
        const factoryFacet = (facetId, field, resultsMustMatch, injectionDepth) => {
            return buildFacet(engine, {
                options: {
                    injectionDepth: injectionDepth || 1000,
                    facetId,
                    field,
                    numberOfValues: 999999,
                    resultsMustMatch: resultsMustMatch || 'allValues',
                    sortCriteria: 'alphanumeric',
                },
            });
        };

        const controllerFacetSpecialty = factoryFacet(
            'specialty',
            'specialties',
            'atLeastOneValue',
            5000,
        );
        const controllerFacetInsurance = factoryFacet('insurance', 'insuranceaccepted');
        const controllerFacetLanguage = factoryFacet('language', 'languages');
        const controllerFacetGender = factoryFacet('gender', 'gender', 'atLeastOneValue');
        const controllerFacetType = factoryFacet('type', 'providertype', 'atLeastOneValue');
        const controllerFacetVisit = factoryFacet('visit', 'additionalvisittypes');
        const controllerFacetAffiliation = factoryFacet('affiliation', 'hospitalaffiliations');
        const controllerFacetSpecialtyService = factoryFacet(
            'specialtyservice',
            'specialtyservices',
        );

        engine.dispatch(FacetSetActions.updateFacetAutoSelection({ allow: true }));

        const controllerBreadcrumbManager = buildBreadcrumbManager(engine);

        const executeSearch = (event, payload = {}) => {
            const {
                logInterfaceLoad,
                logSearchboxSubmit,
                logResultsSort,
                logQuerySuggestionClick,
                logFacetSelect,
                logFacetDeselect,
                logFacetBreadcrumb,
                logClearBreadcrumbs,
                logStaticFilterSelect,
                logStaticFilterDeselect,
                logStaticFilterClearAll,
            } = SearchAnalyticsActionCreators;
            switch (event) {
                case 'resultsSort':
                    return SearchActionCreators.executeSearch(logResultsSort());
                case 'searchboxSubmit':
                    return SearchActionCreators.executeSearch(logSearchboxSubmit());
                case 'querySuggestionClick':
                    return SearchActionCreators.executeSearch(logQuerySuggestionClick(payload)); // LogQuerySuggestionClickActionCreatorPayload {id: string, suggestion: string}
                case 'facetSelect':
                    return SearchActionCreators.executeSearch(logFacetSelect(payload)); // LogFacetSelectActionCreatorPayload {facetId: string, facetValue: string}
                case 'facetDeselect':
                    return SearchActionCreators.executeSearch(logFacetDeselect(payload)); // LogFacetDeselectActionCreatorPayload {facetId: string, facetValue: string}
                case 'facetBreadcrumb':
                    return SearchActionCreators.executeSearch(logFacetBreadcrumb(payload)); // LogFacetBreadcrumbActionCreatorPayload {facetId: string, facetValue: string}
                case 'clearBreadcrumbs':
                    return SearchActionCreators.executeSearch(logClearBreadcrumbs());
                case 'staticFilterSelect':
                    return SearchActionCreators.executeSearch(logStaticFilterSelect(payload)); // LogStaticFilterToggleValueActionCreatorPayload {staticFilterId: string, staticFilterValue: StaticFilterValueMetadata}
                case 'staticFilterDeselect':
                    return SearchActionCreators.executeSearch(logStaticFilterDeselect(payload)); // LogStaticFilterToggleValueActionCreatorPayload {staticFilterId: string, staticFilterValue: StaticFilterValueMetadata}
                case 'staticFilterClearAll':
                    return SearchActionCreators.executeSearch(logStaticFilterClearAll(payload)); // LogStaticFilterClearAllActionCreatorPayload {staticFilterId: string}
                default:
                    return SearchActionCreators.executeSearch(logInterfaceLoad());
            }
        };

        const triggerExecuteSearch = async (urlParams) => {
            setState((prevState) => {
                if (prevState === 'default') {
                    coveoAnalyticsRef.current.shouldReset = false;
                }
                return 'results';
            });

            const { term, sort, 'zip-code': zipCodeParam, city: cityParam } = urlParams;

            setZipCodeServerError(false);
            setZipCode(zipCodeParam);
            setCity(cityParam);

            if (zipCodeParam || cityParam) {
                const newGeolocation = await setGeolocation({
                    zipCode: zipCodeParam,
                    city: cityParam,
                });
                geolocation = newGeolocation;

                if ((!!zipCodeParam || !!cityParam) && newGeolocation == null) {
                    setZipCodeServerError(true);
                    return;
                }
            } else {
                geolocation = null;
            }

            const queryArray = [];
            for (const name in mapUrlParamsToQuery) {
                if (Object.hasOwnProperty.call(mapUrlParamsToQuery, name)) {
                    const fn = mapUrlParamsToQuery[name];
                    queryArray.push(fn(urlParams[name]));
                }
            }
            const query = queryArray.filter(Boolean).join(' AND ');

            const zipCityQueryArray = [];
            for (const name in mapUrlParamsToQueryZipCity) {
                if (Object.hasOwnProperty.call(mapUrlParamsToQueryZipCity, name)) {
                    const fn = mapUrlParamsToQueryZipCity[name];
                    zipCityQueryArray.push(fn(urlParams[name]));
                }
            }
            const zipCityQuery = zipCityQueryArray.filter(Boolean).join(' AND ');

            const distanceQueryArray = [];
            for (const name in mapUrlParamsToQueryDistance) {
                if (Object.hasOwnProperty.call(mapUrlParamsToQueryDistance, name)) {
                    const fn = mapUrlParamsToQueryDistance[name];
                    distanceQueryArray.push(fn(urlParams[name]));
                }
            }

            if (distanceQueryArray.filter(Boolean).length > 0) {
                distanceQueryArray.push(zipCityQuery);
            }

            const distanceQuery = distanceQueryArray
                .filter(Boolean)
                .map((q, index) => (index > 0 ? `(${q})` : q))
                .join(' OR ');

            const advancedQueryArray = [];
            for (const name in mapUrlParamsToAdvancedQuery) {
                if (Object.hasOwnProperty.call(mapUrlParamsToAdvancedQuery, name)) {
                    const fn = mapUrlParamsToAdvancedQuery[name];
                    advancedQueryArray.push(fn(urlParams[name]));
                }
            }
            advancedQueryArray.push(distanceQuery);
            const advancedQuery = advancedQueryArray
                .filter(Boolean)
                .map((q, index) => (index > 0 ? `(${q})` : q))
                .join(' AND ');

            // Do not default Next Available if it is disabled
            if (!disableNextAvailableSort && !term && !zipCodeParam && !cityParam) {
                engine.dispatch(SortCriteriaActions.updateSortCriterion(sortCriteria[1][1]));
            }

            let contextQueryArray = [];
            for (const name in mapContextToQueryZipCity) {
                if (Object.hasOwnProperty.call(mapContextToQueryZipCity, name)) {
                    const fn = mapContextToQueryZipCity[name];
                    contextQueryArray = [...contextQueryArray, ...fn(urlParams[name])];
                }
            }
            const contextQuery = contextQueryArray.filter(Boolean);
            const contextKeys = ['zip', 'city', 'state'];
            contextKeys.forEach((key) => {
                const found = contextQuery.find((query) => query?.contextKey === key);
                if (found) {
                    engine.dispatch(ContextActionCreators.addContext(found));
                } else {
                    engine.dispatch(ContextActionCreators.removeContext(key));
                }
            });

            if (sort) {
                engine.dispatch(
                    SortCriteriaActions.updateSortCriterion(mapUrlParamsToSort.sort(sort)),
                );
            }

            for (const name of urlParamFilterNames) {
                engine.dispatch(FacetSetActions.deselectAllFacetValues(name));

                const values = urlParams[name];
                const filteredValues = values?.filter(Boolean);

                if (!filteredValues || filteredValues.length === 0) {
                    continue;
                }

                filteredValues.forEach((v) => {
                    const [value, numberOfResults] = v.split(';');

                    engine.dispatch(
                        FacetSetActions.toggleSelectFacetValue({
                            facetId: name,
                            selection: {
                                value,
                                numberOfResults: parseInt(numberOfResults, 10),
                                state: 'selected',
                            },
                        }),
                    );
                });

                // Updates the updateFreezeCurrentValues flag of a facet to be 'false' to unfreeze Facet Values
                engine.dispatch(
                    FacetSetActions.updateFreezeCurrentValues({
                        facetId: name,
                        freezeCurrentValues: false,
                    }),
                );

                // Increases the number of facet values to 999999 on the search request
                engine.dispatch(
                    FacetSetActions.updateFacetNumberOfValues({
                        facetId: name,
                        numberOfValues: 999999,
                    }),
                );
            }

            engine.dispatch(
                QueryActionCreators.updateQuery({
                    enableQuerySyntax: true,
                    q: `${query}`,
                }),
            );

            engine.dispatch(
                AdvancedSearchActionCreators.registerAdvancedSearchQueries({
                    aq: `${advancedQuery}`,
                }),
            );

            engine.dispatch(
                executeSearch(
                    coveoAnalyticsRef?.current?.event,
                    coveoAnalyticsRef?.current?.payload,
                ),
            );
        };

        const setCoveoAnalytics = (event = '', payload = {}, shouldReset = true) => {
            coveoAnalyticsRef.current.event = event;
            coveoAnalyticsRef.current.payload = payload;
            coveoAnalyticsRef.current.shouldReset = shouldReset;
        };

        const handleSubmit = ({ term, zipCityValue, insuranceValue }) => {
            const newSearchParams = new URLSearchParams(history.location.search);
            const additionalParams = Object.keys(mapUrlParamsToAdvancedQuery);
            const valuesAdvanceQuery = {};

            for (const name of additionalParams) {
                valuesAdvanceQuery[name] = searchParams.get(name);
            }

            const values = [
                { name: 'term', value: term ?? null },
                {
                    name: 'zip-code',
                    value: !isNaN(zipCityValue) && zipCityValue !== '' ? zipCityValue : null,
                },
                { name: 'city', value: isNaN(zipCityValue) ? zipCityValue : null },
                ...[{ name: 'distance', value: zipCityValue ? '5' : null }],
                ...(insuranceValue ? [{ name: 'insurance', value: insuranceValue }] : []),
                ...Object.entries(valuesAdvanceQuery)
                    .filter(([key, value]) => value)
                    .map(([key, value]) => ({ name: key, value })),
            ];

            values.forEach(({ name, value }) => {
                if (Array.isArray(value) && value.length > 0) {
                    newSearchParams.delete(name);
                    value.forEach((v) => newSearchParams.append(name, v));
                } else if (value || value === '') {
                    newSearchParams.set(name, value);
                } else {
                    newSearchParams.delete(name);
                }
            });

            const path = window.location.pathname;
            const newUrl = `${path}?${newSearchParams.toString()}`;

            const currentPath = window.location.pathname;
            const currentUrl = `${currentPath}${history.location.search}`;

            if (currentUrl === newUrl) {
                return false;
            }
            setQueryCorrected(false);
            history.push(newUrl);
        };

        const handleApplyCorrection = (automatically) => {
            const newSearchParams = new URLSearchParams(history.location.search);
            newSearchParams.set(
                'term',
                controllerDidYouMean?.state?.queryCorrection?.correctedQuery,
            );
            const path = window.location.pathname;
            const newUrl = `${path}?${newSearchParams.toString()}`;
            setQueryCorrected(automatically);
            history.push(newUrl);
        };

        const handleSingleFilter = (partial, analytics) => {
            const newSearchParams = new URLSearchParams(history.location.search);
            for (const name in partial) {
                if (Object.hasOwnProperty.call(partial, name)) {
                    const value = partial[name];

                    if (value) {
                        newSearchParams.set(name, value);
                    } else {
                        newSearchParams.delete(name);
                    }
                }
            }

            const path = window.location.pathname;
            const newUrl = `${path}?${newSearchParams.toString()}`;

            // Analytics
            if (analytics) {
                trackInteraction({
                    componentName: 'fad_results_filter_sort_panel',
                    interactionType: 'fad_result_filter',
                    selector: 'filter',
                    allFilters: newSearchParams,
                    ...analytics,
                });
            }

            history.push(newUrl);
        };

        const handleMultipleFilters = (partial, analytics) => {
            const newSearchParams = new URLSearchParams(history.location.search);
            for (const name in partial) {
                if (Object.hasOwnProperty.call(partial, name)) {
                    const value = partial[name];
                    if (newSearchParams.has(name, value)) {
                        newSearchParams.delete(name, value);
                    } else {
                        const params = newSearchParams.getAll(name);
                        const iname = value?.split(';')[0];
                        const matched = params?.find(
                            (p) => p?.includes(';0') !== -1 && p?.split(';')[0] === iname,
                        );
                        if (matched) {
                            newSearchParams.delete(name, matched);
                        } else {
                            newSearchParams.append(name, value);
                        }
                    }
                }
            }

            const path = window.location.pathname;
            const newUrl = `${path}?${newSearchParams.toString()}`;

            // Analytics
            if (analytics) {
                trackInteraction({
                    componentName: 'fad_results_filter_sort_panel',
                    interactionType: 'fad_result_filter',
                    selector: 'filter',
                    allFilters: newSearchParams,
                    ...analytics,
                });
            }
            history.push(newUrl);
        };

        const handleFilterResetAll = () => {
            const newSearchParams = removeUrlParams(new URLSearchParams(history.location.search), [
                'term',
                'zip-code',
                'city',
            ]);

            const path = window.location.pathname;
            const newUrl = `${path}?${newSearchParams.toString()}`;

            setCoveoAnalytics('clearBreadcrumbs');

            history.push(newUrl);
        };

        const handleScheduling = (patientType) => {
            const newSearchParams = new URLSearchParams(history.location.search);

            if (patientType === 'existing') {
                newSearchParams.set('patient-type', 'existing');
            } else {
                newSearchParams.delete('patient-type');
            }

            const path = window.location.pathname;
            const newUrl = `${path}?${newSearchParams.toString()}`;

            history.push(newUrl);
        };

        return {
            coveo: {
                engine,
                controller: {
                    searchBox: controllerSearchBox,
                    querySummary: controllerQuerySummary,
                    resultList: controllerResultList,
                    sort: controllerSort,
                    facet: {
                        specialty: controllerFacetSpecialty,
                        insurance: controllerFacetInsurance,
                        language: controllerFacetLanguage,
                        gender: controllerFacetGender,
                        type: controllerFacetType,
                        visit: controllerFacetVisit,
                        affiliation: controllerFacetAffiliation,
                        specialtyservice: controllerFacetSpecialtyService,
                    },
                    breadcrumbManager: controllerBreadcrumbManager,
                    didYouMean: controllerDidYouMean,
                },
                action: {
                    updateQuery: QueryActionCreators.updateQuery,
                    executeSearch,
                },
            },
            sortCriteria,
            patientType,
            handleScheduling,
            handleSubmit,
            handleSingleFilter,
            handleMultipleFilters,
            handleFilterResetAll,
            triggerExecuteSearch,
            noResultsComponent,
            errorComponent,
            enableTypeahead,
            disableCalendar,
            disableNextAvailableSort,
            setCoveoAnalytics,
            searchboxId,
            handleApplyCorrection,
            queryCorrected,
        };
    }, [
        engine,
        id,
        enableTypeahead,
        patientType,
        noResultsComponent,
        errorComponent,
        disableCalendar,
        disableNextAvailableSort,
        queryCorrected,
        setState,
        setGeolocation,
        history,
        searchParams,
    ]);

    useEffect(() => {
        let isCancelled = false;

        if (
            !searchParams.has('term') &&
            !searchParams.has('zip-code') &&
            !searchParams.has('city')
        ) {
            return;
        }

        const urlParamQueryNames = [
            ...Object.keys(mapUrlParamsToQuery),
            ...Object.keys(mapUrlParamsToAdvancedQuery),
            ...Object.keys(mapUrlParamsToQueryDistance),
            ...Object.keys(mapUrlParamsToQueryZipCity),
            ...Object.keys(mapUrlParamsToSort),
        ];

        const values = {};
        for (const name of urlParamQueryNames) {
            values[name] = searchParams.get(name);
        }

        for (const name of urlParamFilterNames) {
            values[name] = searchParams.getAll(name);
        }

        const triggerSearch = async () => {
            await value.triggerExecuteSearch(values);

            if (!isCancelled && coveoAnalyticsRef?.current?.shouldReset) {
                value.setCoveoAnalytics();
            }
        };

        triggerSearch();

        return () => {
            isCancelled = true;
        };
    }, [searchParams, setState, value, setZipCode, setCity, setZipCodeServerError]);

    return (
        <ProviderSearchContext.Provider value={value}>
            <ProviderSearchZipCityContext.Provider value={{ zipCode, city }}>
                <ProviderSearchZipCodeErrorContext.Provider value={zipCodeServerError}>
                    {children}
                </ProviderSearchZipCodeErrorContext.Provider>
            </ProviderSearchZipCityContext.Provider>
        </ProviderSearchContext.Provider>
    );
};

export const useProviderSearchContext = () => {
    const context = useContext(ProviderSearchContext);

    if (context == null) {
        throw new Error(`'useProviderSearchContext' needs to be within 'ProviderSearchContext'`);
    }

    return context;
};

export const useProviderSearchZipCityContext = () => {
    const context = useContext(ProviderSearchZipCityContext);

    if (context == null) {
        throw new Error(
            `'useProviderSearchZipCityContext' needs to be within 'ProviderSearchZipCityContext'`,
        );
    }

    return context;
};

export const useProviderSearchZipCodeErrorContext = () => {
    const context = useContext(ProviderSearchZipCodeErrorContext);

    if (context == null) {
        throw new Error(
            `'useProviderSearchZipCodeErrorContext' needs to be within 'ProviderSearchZipCodeErrorContext'`,
        );
    }

    return context;
};
