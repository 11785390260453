import { useCollator } from '@react-aria/i18n';
import { setInteractionModality } from '@react-aria/interactions';
import { useField } from '@react-aria/label';
import { useMenuTrigger } from '@react-aria/menu';
import { ListKeyboardDelegate, useTypeSelect } from '@react-aria/selection';
import { chain, filterDOMProps, mergeProps, useId } from '@react-aria/utils';
import React from 'react';

export const selectData = new WeakMap();
export const useMultiSelect = (props, state, ref) => {
    let { keyboardDelegate, isDisabled, isRequired, name, validationBehavior = 'aria' } = props;

    const collator = useCollator({ usage: 'search', sensitivity: 'base' });
    const delegate = React.useMemo(
        () =>
            keyboardDelegate ??
            new ListKeyboardDelegate(state.collection, state.disabledKeys, null, collator),
        [keyboardDelegate, state.collection, state.disabledKeys, collator],
    );
    const { menuTriggerProps, menuProps } = useMenuTrigger(
        {
            isDisabled,
            type: 'listbox',
        },
        state,
        ref,
    );

    const { typeSelectProps } = useTypeSelect({
        keyboardDelegate: delegate,
        selectionManager: state.selectionManager,
        onTypeSelect: (key) => {
            if (!props?.singleSelectEnabled && state.selectedKeys === 'all') {
                state.setSelectedKeys(
                    [...state.collection].map((item) => item.key).filter((k) => k !== key),
                );
            } else {
                state.setSelectedKeys(state.selectedKeys.push(key));
            }
        },
    });
    let { isInvalid, validationErrors, validationDetails } = state.displayValidation;

    const { labelProps, fieldProps, descriptionProps, errorMessageProps } = useField({
        ...props,
        labelElementType: 'span',
        isInvalid,
        errorMessage: props.errorMessage || validationErrors,
    });

    typeSelectProps.onKeyDown = typeSelectProps.onKeyDownCapture;
    delete typeSelectProps.onKeyDownCapture;

    const domProps = filterDOMProps(props, { labelable: true });
    const triggerProps = mergeProps(typeSelectProps, menuTriggerProps, fieldProps);

    const valueId = useId();

    selectData.set(state, {
        isDisabled,
        isRequired,
        name,
        validationBehavior,
    });

    return {
        labelProps: {
            ...labelProps,
            onClick: () => {
                if (!props.isDisabled) {
                    ref.current?.focus();

                    // Show the focus ring so the user knows where focus went
                    setInteractionModality('keyboard');
                }
            },
        },
        triggerProps: mergeProps(domProps, {
            ...triggerProps,
            onKeyDown: chain(triggerProps.onKeyDown, props.onKeyDown),
            onKeyUp: props.onKeyUp,
            'aria-labelledby': [
                triggerProps['aria-labelledby'],
                triggerProps['aria-label'] && !triggerProps['aria-labelledby']
                    ? triggerProps.id
                    : null,
                valueId,
            ]
                .filter(Boolean)
                .join(' '),
            onFocus(event) {
                if (state.isFocused) {
                    return;
                }

                if (props.onFocus) {
                    props.onFocus(event);
                }

                if (props.onFocusChange) {
                    props.onFocusChange(true);
                }

                state.setFocused(true);
            },
            onBlur(event) {
                if (state.isOpen) {
                    return;
                }

                if (props.onBlur) {
                    props.onBlur(event);
                }

                if (props.onFocusChange) {
                    props.onFocusChange(false);
                }

                state.setFocused(false);
            },
        }),
        valueProps: {
            id: valueId,
        },
        menuProps: {
            ...menuProps,
            shouldSelectOnPressUp: true,
            shouldFocusOnHover: true,
            disallowEmptySelection: true,
            onBlur: (event) => {
                if (event.currentTarget.contains(event.relatedTarget)) {
                    return;
                }

                if (props.onBlur) {
                    props.onBlur(event);
                }

                if (props.onFocusChange) {
                    props.onFocusChange(false);
                }

                state.setFocused(false);
            },
            'aria-labelledby': [
                fieldProps['aria-labelledby'],
                triggerProps['aria-label'] && !fieldProps['aria-labelledby']
                    ? triggerProps.id
                    : null,
            ]
                .filter(Boolean)
                .join(' '),
        },
        descriptionProps,
        errorMessageProps,
        isInvalid,
        validationErrors,
        validationDetails,
    };
};
