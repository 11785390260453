import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import { Button } from '@/design-system/atoms/Button';
import { MultiSelect } from '@/design-system/atoms/Forms/MultiSelect';
import { PlacesTypeahead } from '@/design-system/atoms/Forms/PlacesTypeahead';
import { TextField } from '@/design-system/atoms/Forms/TextField';
import { Typeahead } from '@/design-system/atoms/Forms/Typeahead';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Title } from '@/design-system/atoms/Title';
import IllustrationPlayer from '@/design-system/molecules/IllustrationPlayer';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { trackInteraction } from '@/utils/analytics';
import { locationCheck } from '@/utils/locationCheck';

import { useProviderSearchContext } from '../../../contexts/ProviderSearchContext';
import { useProviderUIContext } from '../../../contexts/ProviderUIContext';
import { formValidation } from '../../../utils/formValidation';
import styles from '../ProviderHero.module.scss';

const ProviderHeroDefault = ({
    inputNameTypeahead,
    inputNameZipCity,
    inputNameInsurance,
    media,
    image,
    illustration,
    hideMobileIllustration,
    searchValue,
    setSearchValue,
    onSelectionItemChange,
    onSelectionZipCityChange,
    typeaheadItems,
    typeaheadFixedItem,
    zipCityValue,
    setZipCityValue,
    onSubmit,
    submitDisabled,
    googleMaps,
    insuranceValue,
    onSelectionInsuranceChange,
    insurancePlans,
    placeSuggestions,
    handleZipCityInputChange,
    zipInvalid,
    setZipInvalid,
    handleAutoSelect,
}) => {
    const breakpoint = useBreakpoint();

    const { title, searchLabel, searchErrorMessage, zipLabel, zipErrorMessage, insuranceLabel } =
        useProviderUIContext();
    const { enableTypeahead, setCoveoAnalytics, searchboxId } = useProviderSearchContext();
    const searchRef = useRef();
    const zipRef = useRef();
    const [searchValueInvalid, setSearchValueInvalid] = useState(false);
    const [searchValueError, setSearchValueError] = useState();
    const [zipError, setZipError] = useState();

    const insuranceOptions = insurancePlans
        ? insurancePlans.map((plan) => ({
              label: plan,
              value: plan,
          }))
        : [];

    const handleSelectionItemChange = useCallback(
        (item) => {
            if (item && searchboxId) {
                setCoveoAnalytics(
                    'querySuggestionClick',
                    {
                        id: searchboxId,
                        suggestion: item?.rawValue,
                    },
                    false,
                );
            }

            onSelectionItemChange?.(item);
        },
        [onSelectionItemChange, setCoveoAnalytics, searchboxId],
    );

    const handleSelectionZipCityChange = useCallback(
        (item) => {
            setCoveoAnalytics('searchboxSubmit', {}, false);
            onSelectionZipCityChange?.(item);
        },
        [onSelectionZipCityChange, setCoveoAnalytics],
    );

    const submitForm = useCallback(
        async (e) => {
            e.preventDefault();

            const { isGeocoded, newLocation } = await locationCheck({
                zipCityValue,
                suggestions: placeSuggestions,
            });

            const [isValid, keys, message] = formValidation(
                {
                    searchValue,
                    zipCityValue: newLocation || zipCityValue,
                    isGeocoded: isGeocoded,
                },
                { searchValue: searchErrorMessage, zipValue: zipErrorMessage },
            );

            if (isValid) {
                setSearchValueInvalid(false);
                setZipInvalid(false);

                setCoveoAnalytics('searchboxSubmit', {}, false);

                onSubmit({
                    term: searchValue,
                    zipCityValue: newLocation || zipCityValue,
                    insuranceValue,
                });

                //analytics
                trackInteraction({
                    eventName: 'search',
                    componentName: 'fad_hero',
                    interactionType: 'fad_search_results',
                    componentTitle: title,
                    searchTerm: searchValue,
                    zipCode: !isNaN(zipCityValue) ? zipCityValue : '',
                    allFilters: '', // TODO: Update this when filters are ready
                });

                return;
            }

            if (keys.includes('zipCityValue') && keys.includes('searchValue')) {
                setZipInvalid(true);
                setSearchValueInvalid(true);
                setZipError(message);
                setSearchValueError(message);
                searchRef.current.focus();
            } else if (keys.includes('zipCityValue')) {
                setSearchValueInvalid(false);
                setZipInvalid(true);
                setZipError(message);
                zipRef.current.focus();
            }
        },
        [
            placeSuggestions,
            onSubmit,
            insuranceValue,
            searchErrorMessage,
            searchValue,
            setCoveoAnalytics,
            title,
            zipErrorMessage,
            zipCityValue,
            setZipInvalid,
        ],
    );

    return (
        <>
            <Grid
                className={classnames(
                    styles[`provider-hero__grid`],
                    hideMobileIllustration &&
                        !breakpoint?.medium &&
                        styles['provider-hero__grid--hidden-illustration'],
                )}
            >
                <GridColumn
                    colSpan={{ sm: 4, md: 6, lg: 5 }}
                    className={styles[`provider-hero__content`]}
                >
                    <Title
                        className={styles[`provider-hero__title`]}
                        variant={Title.VARIANT.H2}
                        tag={Title.TAG.H1}
                        content={title}
                    />
                    <form
                        className={styles[`provider-hero__form`]}
                        onSubmit={submitForm}
                        data-trigger="search"
                    >
                        {enableTypeahead ? (
                            <Typeahead
                                ref={searchRef}
                                name={inputNameTypeahead}
                                label={searchLabel}
                                variant={TextField.VARIANT.BOTTOM_BORDER}
                                buttonProps={undefined}
                                items={typeaheadItems}
                                fixedItem={typeaheadFixedItem}
                                className={styles[`provider-hero__typeahead`]}
                                inputValue={searchValue}
                                onInputChange={setSearchValue}
                                onSelectionItemChange={(item) => handleSelectionItemChange(item)}
                                isInvalid={searchValueInvalid}
                                validationErrors={searchValueError}
                                srError
                            />
                        ) : (
                            <TextField
                                ref={searchRef}
                                name={inputNameTypeahead}
                                label={searchLabel}
                                variant={TextField.VARIANT.BOTTOM_BORDER}
                                buttonProps={undefined}
                                className={styles[`provider-hero__typeahead`]}
                                value={searchValue}
                                onChange={setSearchValue}
                                isInvalid={searchValueInvalid}
                                validationErrors={searchValueError}
                                srError
                            />
                        )}

                        <PlacesTypeahead
                            ref={zipRef}
                            label={zipLabel}
                            inputNameTypeahead={inputNameZipCity}
                            inputValue={zipCityValue}
                            onInputChange={handleZipCityInputChange}
                            onSelectionItemChange={(item) => handleSelectionZipCityChange(item)}
                            onGeolocationSuccess={setZipCityValue}
                            isInvalid={zipInvalid}
                            validationErrors={zipError}
                            className={styles[`location-hero__typeahead`]}
                            googleMaps={googleMaps}
                            autoSelect={{ enabled: true, callback: handleAutoSelect }}
                        />
                        <MultiSelect
                            label={insuranceLabel}
                            name={inputNameInsurance}
                            items={insuranceOptions}
                            defaultSelectedKeys={insuranceValue}
                            variant="bottom-border"
                            className={styles[`provider-hero__multiselect`]}
                            onSelectionChange={onSelectionInsuranceChange}
                            singleSelectEnabled={true}
                        />
                        <div className={styles[`provider-hero__button-wrapper`]}>
                            <Button
                                label="Search"
                                ariaLabel="Submit Provider Search"
                                buttonStyle={Button.STYLE.PRIMARY}
                                className={styles[`provider-hero__button`]}
                                type="submit"
                                disabled={submitDisabled}
                                data-submit-type="provider-search"
                            />
                        </div>
                    </form>
                </GridColumn>
                {hideMobileIllustration && !breakpoint?.medium ? null : (
                    <GridColumn
                        colSpan={{ sm: 4, md: 6, lg: 7 }}
                        colStart={{ sm: 1, md: 1, lg: 6 }}
                        className={styles[`provider-hero__illustration`]}
                    >
                        {media === 'image' && image && <Image {...image} />}
                        {media === 'illustration' && illustration?.scene && illustration?.title && (
                            <IllustrationPlayer {...illustration} />
                        )}
                    </GridColumn>
                )}
            </Grid>
        </>
    );
};

ProviderHeroDefault.propTypes = {
    /**
     * The title of the Provider hero component.
     */
    title: PropTypes.string,
    /**
     * The label for the search input.
     */
    searchLabel: PropTypes.string,
    /**
     * The label for the zip input.
     */
    zipLabel: PropTypes.string,
    /**
     * The media type to display.
     */
    media: PropTypes.oneOf(['image', 'illustration']),
    /**
     * The image to display
     */
    image: PropTypes.object,
    /**
     * The illustration to display.
     */
    illustration: PropTypes.object,
};

export default ProviderHeroDefault;
