import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import atlanticBadge from '@/assets/images/atlantic-health-mini.png';
import placeholder from '@/assets/images/Doctor_Placeholder@2x.png';
import { Button } from '@/design-system/atoms/Button';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { LazyIconArrowRightCircle } from '@/design-system/atoms/Icons/IconArrowRightCircle/Lazy';
import { LazyIconMapPin } from '@/design-system/atoms/Icons/IconMapPin/Lazy';
import { Image } from '@/design-system/atoms/Image';
import { StarRatings } from '@/design-system/atoms/StarRatings';
import { Text } from '@/design-system/atoms/Text';
import { AvailabilityCard } from '@/design-system/molecules/AvailabilityCard';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { summarizeArray } from '@/utils/summarizeArray';

import styles from './ProviderResultCard.module.scss';

const ProviderResultCard = ({
    image,
    title,
    primarySpecialty,
    specialties,
    hospitalAffiliation,
    affiliationBadgeLabel,
    location,
    averageRating,
    availability,
    analytics,
    link,
    onlineBookingAvailable,
    patientType,
    myChartUrl,
    acceptingNewPatients,
    newPatientAppointmentIds,
    notAcceptingPatientsText,
    existingPatientText,
    noOnlineSchedulingText,
    availabilityLabel,
    disableCalendar,
    coveoAnalyticsCallbacks,
    departmentIds,
    ...props
}) => {
    const [badgeLabel, setBadgeLabel] = useState('');
    const breakpoint = useBreakpoint();
    const isMobile = !breakpoint?.medium;
    const ImageComponent = image ? (
        <Image {...image} fallbackSrc={placeholder} alt={title} roundedCorners />
    ) : (
        <Image src={placeholder} alt="Doctor" roundedCorners />
    );

    const specialtiesText = summarizeArray(specialties, 3, primarySpecialty);
    // dedupe locations
    const locationsText = location
        .reduce((acc, location) => {
            if (!acc.includes(location)) {
                acc.push(location);
            }

            return acc;
        }, [])
        .join(', ');

    const analyticsObj = useMemo(() => {
        return {
            ...analytics,
            componentName: 'fad_results_card',
            providerName: title,
            providerSpecialties: specialtiesText,
            providerRating: averageRating,
            acceptingNewPatients: acceptingNewPatients?.toString(),
        };
    }, [title, specialtiesText, averageRating, analytics, acceptingNewPatients]);

    const eventHandlers = useMemo(
        () => ({
            onClick: coveoAnalyticsCallbacks?.onClick,
            onContextMenu: coveoAnalyticsCallbacks?.onContextMenu,
            onMouseDown: coveoAnalyticsCallbacks?.onMouseDown,
            onMouseUp: coveoAnalyticsCallbacks?.onMouseUp,
            onTouchStart: coveoAnalyticsCallbacks?.onTouchStart,
            onTouchEnd: coveoAnalyticsCallbacks?.onTouchEnd,
        }),
        [coveoAnalyticsCallbacks],
    );

    useEffect(() => {
        coveoAnalyticsCallbacks?.cancelPendingSelect?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (affiliationBadgeLabel) {
            setBadgeLabel(affiliationBadgeLabel?.length ? affiliationBadgeLabel[0].title : '');
        }
    }, [affiliationBadgeLabel]);

    const renderTitle = () => (
        <>
            {acceptingNewPatients && (
                <Text
                    content="Accepting New Patients"
                    tag={Text.TAG.P}
                    variant={Text.VARIANT.T4}
                    className={styles[`provider-result-card__accepting-badge`]}
                />
            )}

            <Button
                label={title}
                buttonStyle={Button.STYLE.TEXT_TITLE}
                className={styles[`provider-result-card__title`]}
                iconComponent={LazyIconArrowRightCircle}
                {...link}
                csr={false}
                analytics={{
                    ...analyticsObj,
                    interactionType: 'fad_result_cta',
                }}
                {...eventHandlers}
            />
        </>
    );

    const renderSpecialties = () =>
        specialtiesText && (
            <Text content={specialtiesText} tag={Text.TAG.P} variant={Text.VARIANT.T2} />
        );

    const renderAffiliation = () =>
        badgeLabel !== '' ? (
            <div className={styles[`provider-result-card__affiliation`]}>
                <Image
                    src={atlanticBadge}
                    alt="Affiliation"
                    className={styles[`provider-result-card__affiliation-image`]}
                />
                <Text content={badgeLabel} tag={Text.TAG.P} variant={Text.VARIANT.T3} />
            </div>
        ) : null;

    const renderFooter = () => (
        <div className={styles[`provider-result-card__footer`]}>
            {locationsText && (
                <div className={styles[`provider-result-card__location`]}>
                    <LazyIconMapPin />
                    <Text content={locationsText} tag={Text.TAG.P} variant={Text.VARIANT.T3} />
                </div>
            )}
            {typeof averageRating === 'number' && averageRating > 0 && (
                <div className={styles[`provider-result-card__rating`]}>
                    <StarRatings total={5} ratings={averageRating} />
                    <Text
                        content={averageRating?.toFixed(1)}
                        tag={Text.TAG.P}
                        variant={Text.VARIANT.T3}
                    />
                </div>
            )}
        </div>
    );

    const renderMobile = () => (
        <>
            <GridColumn
                colSpan={{ sm: 3 }}
                colStart={{ sm: 2 }}
                rowStart={{ sm: 1 }}
                rowSpan={{ sm: 1 }}
                className={styles[`provider-result-card__doctor`]}
            >
                {renderTitle()}
            </GridColumn>
            <GridColumn
                colSpan={{ sm: 4 }}
                colStart={{ sm: 1 }}
                rowStart={{ sm: 2 }}
                rowSpan={{ sm: 1 }}
                className={styles[`provider-result-card__specialty`]}
            >
                {renderSpecialties()}
                {renderAffiliation()}
                {renderFooter()}
            </GridColumn>
        </>
    );

    const renderDesktop = () => (
        <GridColumn
            colSpan={{ md: 4, lg: 5 }}
            colStart={{ md: 3, lg: 3 }}
            rowStart={{ md: 1, lg: 1 }}
            rowSpan={{ md: 1, lg: 1 }}
        >
            <div className={styles[`provider-result-card__doctor`]}>{renderTitle()}</div>
            {renderSpecialties()}
            {renderAffiliation()}
            {renderFooter()}
        </GridColumn>
    );

    return (
        <Grid
            className={styles[`provider-result-card`]}
            {...props}
            col={{ sm: 4, md: 6, lg: 12 }}
            row={{ sm: 3, md: 3, lg: 1 }}
        >
            <GridColumn
                colSpan={{ sm: 1, md: 2, lg: 2 }}
                colStart={{ sm: 1, md: 1, lg: 1 }}
                rowStart={{ sm: 1, md: 1, lg: 1 }}
                rowSpan={{ sm: 1, md: 2, lg: 1 }}
                className={styles[`provider-result-card__image-container`]}
            >
                {ImageComponent}
            </GridColumn>
            {isMobile ? renderMobile() : renderDesktop()}
            <GridColumn
                colSpan={{ sm: 4, md: 6, lg: 5 }}
                colStart={{ sm: 1, md: 1, lg: 8 }}
                rowStart={{ sm: 3, md: 3, lg: 1 }}
                rowSpan={{ sm: 1, md: 1, lg: 2 }}
                className={styles[`provider-result-card__availability`]}
            >
                <AvailabilityCard
                    {...availability}
                    onlineBookingAvailable={onlineBookingAvailable}
                    acceptingNewPatients={acceptingNewPatients}
                    patientType={patientType}
                    newPatientAppointmentIds={newPatientAppointmentIds}
                    notAcceptingPatientsText={notAcceptingPatientsText}
                    existingPatientText={existingPatientText}
                    myChartUrl={myChartUrl}
                    noOnlineSchedulingText={noOnlineSchedulingText}
                    availabilityLabel={availabilityLabel}
                    analytics={analyticsObj}
                    disableCalendar={disableCalendar}
                    departmentIds={departmentIds}
                />
            </GridColumn>
        </Grid>
    );
};

ProviderResultCard.propTypes = {
    /**
     * Image of the provider
     */
    image: Image.PropTypes,
    /**
     * Whether the provider is accepting new patients
     */
    acceptingNewPatients: PropTypes.bool,
    /**
     * Name of the provider
     */
    title: PropTypes.string,
    /**
     * Primary specialty of the provider
     */
    primarySpecialty: PropTypes.string,
    /**
     * List of specialties of the provider
     */
    specialties: PropTypes.array,
    /**
     * List of hospitals the provider is affiliated
     */
    hospitalAffiliation: PropTypes.arrayOf(PropTypes.string),
    /**
     * whether a doctor has an Affiliation = Atlantic Medical Group
     */
    hasAffiliationBadge: PropTypes.bool,
    /**
     * whether a doctor has an Affiliation label dfined in AEM
     */
    affiliationBadgeLabel: PropTypes.arrayOf(PropTypes.string),
    /**
     * Location of the provider
     */
    location: PropTypes.array,
    /**
     * Average rating of the provider
     */
    averageRating: PropTypes.number,
    /**
     * MyChart URL of the provider
     */
    myChartUrl: PropTypes.string,
    /**
     * Availability of the provider
     */
    availability: AvailabilityCard.PropTypes,
    /**
     * Link object
     */
    link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
        rel: PropTypes.string,
        csr: PropTypes.bool,
        ariaLabel: PropTypes.string,
    }),
};

export default ProviderResultCard;
