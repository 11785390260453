import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '@/design-system/atoms/Text/Text';

import { RadioGroup } from '../RadioGroup';
import { Radio } from '../RadioGroup/Radio/Radio';
import { TextField } from '../TextField';
import styles from './SearchableRadio.module.scss';

/**
 * SearchableRadio Component.
 *
 * This component uses 'Radio', 'RadioGroup', and 'TextField' components.
 *
 * Example of using the component:
 *
 * ```
 * const SearchableRadioStory = (props) => {
 *     const defaultSelected = props.items
 *         .filter((item) => item.defaultSelected)
 *         .map((item) => item.value);
 *
 *     const [selectedOption, setSelectedOption] = React.useState(defaultSelected);
 *
 *     const handleRadioChange = (checked, value) => {
 *         if (checked) {
 *             setSelectedOption(value);
 *         }
 *     };
 *
 *     return (
 *         <>
 *             {selectedOption && (
 *                 <div style={{ marginBottom: 32 }}>
 *                     <p>Selected Option:</p>
 *                     <p>{selectedOption}</p>
 *                 </div>
 *             )}
 *
 *             <SearchableRadio {...props} onRadioChange={handleRadioChange} selectedOption={selectedOption} />
 *         </>
 *     );
 * };
 * ```
 */

export const SearchableRadio = (props) => {
    const {
        text,
        label,
        iconComponent,
        items = [],
        selectedOption,
        onRadioChange,
        onTextFieldKeyDown,
        isScrollable = false,
        fixedOption,
    } = props;
    const [keyword, setKeyword] = React.useState('');

    const fixedItem = items.find((item) => item.value === fixedOption);

    const filteredOptions = (() => {
        if (!keyword) {
            return items;
        }

        return items.filter(
            (item) => item.value.toLowerCase().indexOf(keyword.toLowerCase().trim()) > -1,
        );
    })();

    const handleTextFieldKeyDown = React.useCallback(
        (e) => {
            onTextFieldKeyDown?.(e);
        },
        [onTextFieldKeyDown],
    );

    return (
        <div className={classnames(styles['searchable-radio'])}>
            <fieldset className={styles['searchable-radio__wrapper']}>
                {text && (
                    <legend>
                        <Text
                            className={classnames(styles['searchable-radio__text'])}
                            tag={Text.TAG.DIV}
                            variant={Text.VARIANT.T3}
                            content={text}
                        />
                    </legend>
                )}
                <div className={classnames(styles['searchable-radio__search'])}>
                    <TextField
                        label={label}
                        iconComponent={iconComponent}
                        value={keyword}
                        onChange={setKeyword}
                        onKeyDown={handleTextFieldKeyDown}
                    />
                </div>
                <div
                    className={classnames(
                        isScrollable &&
                            filteredOptions.length > 8 &&
                            styles['searchable-radio__scrollable'],
                    )}
                >
                    <ul className={classnames(styles['searchable-radio__list'])}>
                        <RadioGroup
                            className={styles[`provider-filter__radio-group`]}
                            value={selectedOption}
                            onChange={onRadioChange}
                        >
                            {fixedItem && (
                                <li className={styles[`searchable-radio__list--fixed-item`]}>
                                    <Radio
                                        key={fixedItem.value}
                                        value={fixedItem.value}
                                        label={fixedItem.label}
                                        {...fixedItem}
                                    />
                                </li>
                            )}
                            {filteredOptions.map((item) => {
                                const { value, label } = item;
                                if (fixedItem && value === fixedItem.value) {
                                    return null;
                                }
                                return (
                                    <li key={value}>
                                        <Radio value={value} label={label} {...item} />
                                    </li>
                                );
                            })}
                        </RadioGroup>
                    </ul>
                </div>
            </fieldset>
        </div>
    );
};

SearchableRadio.propTypes = {
    /**
     * text above the Input field
     */
    text: PropTypes.string,
    /**
     * Input Label
     */
    label: PropTypes.string,
    /**
     * Input Icon
     */
    iconComponent: PropTypes.elementType,
    /**
     * List of items (checkboxes)
     */
    items: PropTypes.arrayOf(PropTypes.shape(Radio.propTypes)),
    /**
     * This function is triggered every time that a checkbox is checked/unchecked
     */
    onRadioChange: PropTypes.func,
    /**
     * whether the checkbox list is scrollable
     */
    isScrollable: PropTypes.bool,
    /**
     * Selected value
     */
    selectedOption: PropTypes.string,
    /**
     * Fixed option at the top of the list
     */
    fixedOption: PropTypes.string,
};
