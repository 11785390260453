import classNames from 'classnames';
import React from 'react';

import { Carousel } from '@/design-system/atoms/Carousel';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Loading } from '@/design-system/atoms/Loading';
import { Text } from '@/design-system/atoms/Text';
import Typography from '@/design-system/atoms/Typography';
import { usePhysicianBioAvailabilityDate } from '@/hooks/usePhysicianBioAvailabilityDate';
import { addDays, normalizeTimeSlots } from '@/utils/normalizeTimeSlots';

import styles from './AvailabilityCalendar.module.scss';

const CalendarCard = ({ weekday, monthDay, times, cardIndex, ...props }) => {
    return (
        <div className={styles['availability-calendar__card-wrapper']} {...props}>
            <div className={styles['availability-calendar__card-header']}>
                <Text
                    tag="span"
                    variant={Text.VARIANT.T3}
                    content={weekday}
                    className={styles['availability-calendar__card-weekday']}
                />
                <Text
                    tag="span"
                    variant={Text.VARIANT.T2B}
                    content={monthDay}
                    className={styles['availability-calendar__card-month-day']}
                />
            </div>
            <Grid
                className={styles['availability-calendar__card-content']}
                data-card-index={cardIndex}
                col={{ sm: 3, md: 1, lg: 1 }}
                row={{ sm: 1, md: 3, lg: 3 }}
            >
                {[0, 1, 2].map((el, index) => (
                    <GridColumn
                        className={styles['availability-calendar__card-time-column']}
                        key={index}
                        colStart={{ sm: index + 1, md: 1, lg: 1 }}
                        colSpan={{ sm: 1, md: 1, lg: 1 }}
                        rowStart={{ sm: 1, md: index + 1, lg: index + 1 }}
                        rowSpan={{ sm: 1, md: 1, lg: 1 }}
                    >
                        <Text
                            tag="span"
                            variant={Text.VARIANT.CTA4}
                            content={
                                times[index] || (
                                    <svg
                                        width="33"
                                        height="1"
                                        viewBox="0 0 33 1"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="0.5"
                                            y1="0.25"
                                            x2="32.5"
                                            y2="0.25"
                                            stroke="#667085"
                                            strokeWidth="0.5"
                                        />
                                    </svg>
                                )
                            }
                            className={classNames(
                                styles['availability-calendar__card-time'],
                                !times[index] && styles['availability-calendar__card-time--empty'],
                            )}
                            data-slot-index={index}
                        />
                    </GridColumn>
                ))}
            </Grid>
        </div>
    );
};
const fetchTimeSlots = async (argsObj, signal = null) => {
    const paramNames = ['npi', 'startDate', 'endDate', 'visitTypeIds'];
    const apiPath = `/bin/ahs/providerAvailability`;
    const { startDate, endDate } = argsObj;
    const newSearchParams = new URLSearchParams();
    for (const name of paramNames) {
        if (argsObj[name]) {
            newSearchParams.append(name, argsObj[name]);
        }
    }
    const apiUrl = `${apiPath}?${newSearchParams.toString()}`;
    const response = await fetch(apiUrl, { signal });

    if (!response.ok) {
        throw new Error(`Error: Status ${response.status},${response.message}`);
    }
    const data = await response.json();
    const slides = normalizeTimeSlots(data?.timeSlots, startDate, endDate)?.map((slot, index) => (
        <AvailabilityCalendar.Card
            fullHeight
            key={index}
            className={styles[`availability-calendar__card`]}
        >
            <CalendarCard {...slot} cardIndex={index} />
        </AvailabilityCalendar.Card>
    ));
    return slides;
};

const AvailabilityCalendar = ({ npi, newPatientAppointmentIds, departmentIds, analytics }) => {
    const { availabilityDate, timeSlots } = usePhysicianBioAvailabilityDate({
        npi,
        newPatientAppointmentIds,
        departmentIds,
    });
    const [items, setItems] = React.useState(null);
    const [startdate, setStartdate] = React.useState(null);
    const visitTypeIds = React.useMemo(() => {
        return newPatientAppointmentIds?.filter((item) => item).join(',');
    }, [newPatientAppointmentIds]);

    const fetchNextBatch = React.useCallback(
        async (signal = null) => {
            if (!npi || !startdate) {
                return;
            }
            try {
                const newItems = await fetchTimeSlots(
                    {
                        npi,
                        startDate: startdate,
                        endDate: addDays(startdate, 8),
                        visitTypeIds,
                    },
                    signal,
                );
                if (newItems?.length > 0) {
                    setTimeout(() => {
                        setItems((prevItems) => [...prevItems, ...newItems]);
                        setStartdate((prevDate) => addDays(prevDate, 9));
                    }, [500]);
                }
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.log('Fetch Aborted');
                    return;
                }
            }
        },
        [npi, visitTypeIds, startdate],
    );

    const loadMoreSlide = React.useMemo(() => {
        return (
            <AvailabilityCalendar.Card
                fullHeight
                key={'load-more-slide'}
                className={styles[`availability-calendar__card`]}
            >
                <Loading description={'Loading'} variant={Typography.VARIANT.T2} />
            </AvailabilityCalendar.Card>
        );
    }, []);

    React.useEffect(() => {
        if (availabilityDate && timeSlots) {
            const slides = timeSlots.map((slot, index) => (
                <AvailabilityCalendar.Card
                    fullHeight
                    key={index}
                    className={styles[`availability-calendar__card`]}
                >
                    <CalendarCard {...slot} cardIndex={index} />
                </AvailabilityCalendar.Card>
            ));
            setStartdate(addDays(availabilityDate, 4));
            setItems(slides);
        }
    }, [availabilityDate, timeSlots, npi]);

    return items?.length > 0 ? (
        <div className={styles[`availability-calendar`]}>
            <Carousel.Root>
                <Carousel.InfiniteContent
                    items={items}
                    className={styles[`availability-calendar__carousel`]}
                    fetchData={(signal) => fetchNextBatch(signal)}
                    loadMoreSlide={loadMoreSlide}
                />
                <Carousel.Navigation
                    buttonLeftClassName={styles[`availability-calendar__carousel-navigation--left`]}
                    buttonRightClassName={
                        styles[`availability-calendar__carousel-navigation--right`]
                    }
                    buttonLeftAnalytics={{
                        ...analytics,
                        interactionType: 'schedule_previous_button',
                    }}
                    buttonRightAnalytics={{
                        ...analytics,
                        interactionType: 'schedule_next_button',
                    }}
                />
            </Carousel.Root>
        </div>
    ) : (
        <></>
    );
};
AvailabilityCalendar.Card = Carousel.Card;

export default AvailabilityCalendar;
