import { buildInteractiveResult } from '@coveo/headless';
import * as customPropTypes from 'custom-prop-types';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Button } from '@/design-system/atoms/Button';
import { Loading } from '@/design-system/atoms/Loading';
import { ProviderResultCard } from '@/design-system/molecules/ProviderResultCard';
import { ProviderScheduling } from '@/features/ProviderSearch/components/ProviderScheduling';
import { arrayMatchValues } from '@/utils/arrayMatchValues';

import {
    useProviderSearchContext,
    useProviderSearchZipCityContext,
    useProviderSearchZipCodeErrorContext,
} from '../../contexts/ProviderSearchContext';
import { useProviderUIContext } from '../../contexts/ProviderUIContext';
import styles from './ProviderResultsList.module.scss';

const ProviderResultsList = ({
    schedulingOverrideLabels,
    cta,
    analytics,
    patientTypeId,
    newPatientAppointmentIds,
    affiliationBadgeDictionary,
}) => {
    const {
        coveo: {
            engine,
            controller: { resultList, querySummary },
        },
        patientType,
        noResultsComponent,
        errorComponent,
        disableCalendar,
    } = useProviderSearchContext();

    const {
        notAcceptingPatientsText,
        existingPatientText,
        noOnlineSchedulingText,
        availabilityLabel,
    } = useProviderUIContext();
    const { zipCode } = useProviderSearchZipCityContext();
    const zipCodeServerError = useProviderSearchZipCodeErrorContext();

    const [resultListState, setResultListState] = React.useState({});
    const [results, setResults] = React.useState([]);
    const [querySummaryState, setQuerySummaryState] = React.useState(querySummary.state);

    /**
     * Validate provider affiliations
     * @param { object } result - provider data
     * @returns {Array} the provider affiliation array
     */

    const handleAffiliationBadge = useCallback(
        (result) => {
            if (Object.keys(result).length <= 0) {
                return null;
            }

            const affiliationBadge =
                result.raw.practicegroups?.length && affiliationBadgeDictionary?.length
                    ? arrayMatchValues(
                          result.raw.practicegroups,
                          affiliationBadgeDictionary?.map((badge) => badge.value),
                      )
                    : null;

            if (affiliationBadge?.length) {
                return affiliationBadgeDictionary.filter(
                    (badge) => badge.value === affiliationBadge,
                );
            }

            return [];
        },
        [affiliationBadgeDictionary],
    );

    React.useEffect(() => {
        return resultList.subscribe(() => {
            setResultListState(resultList.state);

            const newResults = resultList.state.results.map((s) => {
                const interactiveResultController = engine
                    ? buildInteractiveResult(engine, {
                          options: { result: s },
                      })
                    : null;
                let image;
                if (s.raw.imageurl) {
                    image = {
                        defaultImgSrc: s.raw.imageurl,
                    };
                }

                let location = [];
                if (s.raw.locationcitystate) {
                    location = s.raw.locationcitystate;
                } else if (
                    s.raw.locationcity &&
                    s.raw.locationstate &&
                    Array.isArray(s.raw.locationcity)
                ) {
                    for (let [, city] of s.raw.locationcity.entries()) {
                        location.push(city);
                    }
                }

                const affiliationBadgeLabel = handleAffiliationBadge(s);

                const link = {
                    href: new URL(s.clickUri).pathname,
                    target: '_self',
                    csr: true,
                    ariaLabel: `Browse to ${s.title}'s profile`,
                };

                const availability = {
                    phoneNumber:
                        s.raw.locationphone && Array.isArray(s.raw.locationphone)
                            ? s.raw.locationphone[0]
                            : null,
                    schedulingCta: {
                        href: `${new URL(s.clickUri).pathname}#scheduling`,
                        target: '_self',
                        csr: true,
                        ariaLabel: `Browse to ${s.title}'s times and schedule`,
                    },
                    npi: s.raw.providernpi,
                    nextAvailabilityDate: !isNaN(new Date(s.raw.nextavailabilitydate)?.getTime())
                        ? new Date(s.raw.nextavailabilitydate).toISOString().substring(0, 10)
                        : null,
                };

                // Specialties to ignore
                const ignoreSpecialties = ['Primary Care'];
                // Filter out ignored specialties
                const specialties = Array.isArray(s.raw.specialties)
                    ? s.raw.specialties.filter((specItem) => !ignoreSpecialties.includes(specItem))
                    : [];

                const departmentIds = s.raw.locepicdepartmentid
                    ?.split(';')
                    ?.filter(Boolean)
                    ?.join(',');

                const item = {
                    id: s.permanentid,
                    image,
                    acceptingNewPatients: s.raw.acceptingnewpatients === 'True' ? true : false,
                    onlineBookingAvailable: s.raw.onlinebookingavailable === 'True' ? true : false,
                    title: s.title.replace(',', ', '),
                    primarySpecialty: s.raw.primaryspecialty,
                    specialties: specialties,
                    hospitalAffiliation: s.raw.hospitalAffiliations,
                    location,
                    averageRating: s.raw.overallrating,
                    position: {
                        lat: s.raw.latitude,
                        lng: s.raw.longitude,
                    },
                    affiliationBadgeLabel,
                    patientType,
                    link,
                    availability,
                    myChartUrl: s.raw.mycharturl,
                    coveoAnalyticsCallbacks: interactiveResultController
                        ? {
                              cancelPendingSelect: () =>
                                  interactiveResultController.cancelPendingSelect(),
                              onClick: () => interactiveResultController.select(),
                              onContextMenu: () => interactiveResultController.select(),
                              onMouseDown: () => interactiveResultController.select(),
                              onMouseUp: () => interactiveResultController.select(),
                              onTouchStart: () => interactiveResultController.beginDelayedSelect(),
                              onTouchEnd: () => interactiveResultController.cancelPendingSelect(),
                          }
                        : undefined,
                    departmentIds,
                };

                return item;
            });

            setResults(newResults);
        });
    }, [patientType, resultList, engine, affiliationBadgeDictionary, handleAffiliationBadge]);

    React.useEffect(() => {
        const unsubscribe = querySummary.subscribe(() => {
            setQuerySummaryState(querySummary.state);
        });
        return () => {
            // Unsubscribe from the query summary state
            unsubscribe();
        };
    }, [querySummary]);

    const handleLoadMoreClick = React.useCallback(
        (e) => {
            e.preventDefault();

            resultList.fetchMoreResults();
        },
        [resultList],
    );

    const state = [
        { state: 'error', valid: resultListState.hasError || zipCodeServerError },
        { state: 'loading', valid: resultListState.isLoading },
        { state: 'noResults', valid: results.length === 0 },
    ].find(({ valid }) => valid)?.state;

    const showResults = results.length > 0;

    if (!state && showResults === false) {
        return null;
    }

    return (
        <>
            {results.length > 0 && (
                <ProviderScheduling
                    id={patientTypeId}
                    analytics={analytics}
                    schedulingOverrideLabels={schedulingOverrideLabels}
                    searchValue={querySummaryState.query}
                />
            )}
            <div className={styles['provider-results-list']}>
                {showResults && (
                    <ul className={styles['provider-results-list__wrapper']}>
                        {results?.map((r, index) => (
                            <li key={`${r.id}-${index}`}>
                                {/* TODO: Check field names in final coveo result data */}
                                <ProviderResultCard
                                    {...r}
                                    newPatientAppointmentIds={newPatientAppointmentIds}
                                    analytics={{
                                        ...analytics,
                                        searchTerm: querySummaryState.query,
                                        zipCode,
                                    }}
                                    notAcceptingPatientsText={notAcceptingPatientsText}
                                    existingPatientText={existingPatientText}
                                    noOnlineSchedulingText={noOnlineSchedulingText}
                                    availabilityLabel={availabilityLabel}
                                    disableCalendar={disableCalendar}
                                />
                            </li>
                        ))}
                    </ul>
                )}

                {state === 'error' && <>{errorComponent}</>}

                {state === 'loading' && (
                    <div className={styles['provider-results-list__overlay']}>
                        <Loading />
                    </div>
                )}

                {state === 'noResults' && <>{noResultsComponent}</>}
            </div>
            {showResults && resultListState.moreResultsAvailable && (
                <div className={styles['provider-results-list__load-more']}>
                    <Button
                        label={cta?.label ? cta.label : 'More Results'}
                        ariaLabel={cta?.ariaLabel ? cta.ariaLabel : 'Load More Results'}
                        buttonStyle={Button.STYLE.SECONDARY}
                        onClick={handleLoadMoreClick}
                        disabled={resultListState.isLoading}
                        analytics={{
                            ...analytics,
                            eventName: 'search',
                            componentName: 'fad_results_list',
                            searchTerm: querySummaryState.query,
                        }}
                    />
                </div>
            )}
        </>
    );
};

ProviderResultsList.propTypes = {
    /**
     * Call to action for loading more results
     */
    cta: PropTypes.shape(Button.propTypes),
    analytics: customPropTypes.analyticsPropType,
    patientTypeId: PropTypes.string,
    schedulingOverrideLabels: PropTypes.shape({
        eyebrow: PropTypes.string,
        description: PropTypes.string,
        newPatientLabel: PropTypes.string,
        existingPatientLabel: PropTypes.string,
        dropdownLabel: PropTypes.string,
    }),
    affiliationBadgeDictionary: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
};

export default ProviderResultsList;
