import * as changeCase from 'change-case';
import classnames from 'classnames';
import { useGeolocationState } from 'contexts/GeolocationContext';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Breadcrumb } from '@/design-system/atoms/Breadcrumb';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Theme } from '@/design-system/atoms/Theme';
import { useSearchParams } from '@/hooks/useSearchParams';
import { trackInteraction } from '@/utils/analytics';

import { useProviderFilterContext } from '../../contexts/ProviderFilterContext';
import {
    useProviderSearchContext,
    useProviderSearchZipCityContext,
} from '../../contexts/ProviderSearchContext';
import { useProviderStateContext } from '../../contexts/ProviderStateContext';
import { useProviderUIContext } from '../../contexts/ProviderUIContext';
import styles from './ProviderHero.module.scss';
import { ProviderHeroDefault } from './ProviderHeroDefault';
import { ProviderHeroResults } from './ProviderHeroResults';

/**
 * The Provider hero component leads the Provider search pages and has two states: default and results.
 */
const ProviderHero = ({ breadcrumbs, hideMobileIllustration, insurancePlans, ...props }) => {
    const searchParams = useSearchParams();

    const {
        coveo: {
            controller: { searchBox, resultList },
        },
        handleSubmit,
        enableTypeahead,
    } = useProviderSearchContext();

    const { zipCode } = useProviderSearchZipCityContext();

    const { title, insuranceLabel } = useProviderUIContext();

    const { openPanel } = useProviderFilterContext();

    const state = useProviderStateContext();

    const [searchValue, setSearchValue] = useState(searchParams.get('term') ?? '');
    const [typeaheadItems, setTypeaheadItems] = useState([]);

    const geolocationState = useGeolocationState();
    const [zipCityDefaultValue, setZipCityDefaultValue] = useState(
        searchParams.get('zip-code') ||
            searchParams.get('city') ||
            geolocationState?.recentSearch ||
            '',
    );

    const [zipCityResultsValue, setZipCityResultsValue] = useState(
        searchParams.get('zip-code') || searchParams.get('city') || '',
    );

    const setZipCityValue = useCallback(
        (value) => {
            setZipCityDefaultValue(value);
            setZipCityResultsValue(value);
        },
        [setZipCityDefaultValue, setZipCityResultsValue],
    );

    const zipCityValue = state === 'default' ? zipCityDefaultValue : zipCityResultsValue;

    const insuranceParams = searchParams.get('insurance') ?? null;
    const [insuranceValue, setInsuranceValue] = useState(insuranceParams?.split(',') || []);

    const [isLoading, setIsLoading] = useState(false);

    const initialLoadRef = useRef(true);

    const [placeSuggestions, setPlaceSuggestions] = useState([]);

    const [zipInvalid, setZipInvalid] = useState(false);

    useEffect(() => {
        return resultList?.subscribe(() => {
            setIsLoading(resultList.state.isLoading);
        });
    }, [resultList]);

    useEffect(() => {
        if (enableTypeahead && searchBox) {
            return searchBox.subscribe(() => {
                const newTypeaheadItems = searchBox.state.suggestions.map((s, i) => ({
                    id: i,
                    text: changeCase.capitalCase(s.rawValue),
                    rawValue: s.rawValue,
                }));

                setTypeaheadItems(newTypeaheadItems);
            });
        }
    }, [searchBox, enableTypeahead]);

    useEffect(() => {
        setSearchValue(searchParams.get('term') ?? '');
    }, [searchParams]);

    useEffect(() => {
        if (state !== 'default') return;
        const actionLabel = insuranceValue?.map((item) => item.replaceAll(';0', ''))?.join(',');
        // analytics
        !initialLoadRef.current &&
            trackInteraction({
                componentName: 'fad_hero',
                componentTitle: insuranceLabel || '',
                eventName: 'search',
                interactionType: 'fad_filter',
                actionLabel: actionLabel,
                selector: 'insurance',
                zipCode: zipCode,
                searchValue: searchValue,
                allFilters: searchParams,
            });
        if (initialLoadRef.current) {
            initialLoadRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceValue]);

    // Submit search and normalize the values based on the state
    const submitSearch = useCallback(
        ({ term, zipCityValue, insuranceValue }) => {
            setZipCityValue(zipCityValue);
            handleSubmit({
                term,
                zipCityValue,
                insuranceValue: state === 'default' ? insuranceValue : undefined, // only send insurance value if in default state,
            });
        },
        [state, handleSubmit, setZipCityValue],
    );

    const handleSetSearchValue = useCallback(
        (value) => {
            if (enableTypeahead && searchBox) {
                if (value.length >= 2) {
                    searchBox.updateText(value);
                } else {
                    searchBox.clear();
                }
            }

            setSearchValue(value);
        },
        [searchBox, enableTypeahead],
    );

    const handleSelectSearchValue = useCallback(
        (item) => {
            if (enableTypeahead && item === null) {
                searchBox.clear();
                return;
            }

            //analytics
            trackInteraction({
                componentName: 'FAD Hero',
                componentTitle: title,
                eventName: 'search',
                interactionType: 'predictive_search_click',
                actionLabel: item.text,
                selector: 'search',
                searchTerm: searchValue,
            });

            submitSearch({
                term: item.text,
                zipCityValue,
                insuranceValue,
            });
        },
        [
            title,
            searchValue,
            submitSearch,
            zipCityValue,
            insuranceValue,
            searchBox,
            enableTypeahead,
        ],
    );

    const handleSelectZipCityValue = useCallback(
        (item) => {
            if (item === null) {
                return;
            }

            // analytics
            trackInteraction({
                componentName: 'fad_hero',
                componentTitle: title,
                eventName: 'search',
                interactionType: 'predictive_search_click',
                actionLabel: item.text,
                selector: 'search',
                searchTerm: item.text,
            });

            setZipInvalid(false);

            submitSearch({
                term: searchValue,
                zipCityValue: item.text,
                insuranceValue,
            });
        },
        [submitSearch, insuranceValue, searchValue, title],
    );

    const handleSelectInsuranceValue = useCallback((items) => {
        if (items === null) {
            return;
        }

        const insuranceValue = [...items].map((item) => `${item};0`);

        setInsuranceValue(insuranceValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleZipCityInputChange = useCallback(
        (value, suggestions) => {
            setZipCityValue(value);
            setPlaceSuggestions(suggestions);
        },
        [setZipCityValue, setPlaceSuggestions],
    );

    const handleAutoSelect = useCallback(
        (e, value) => {
            const triggerEl = e?.relatedTarget;
            if (triggerEl?.getAttribute('data-submit-type') === 'provider-search') {
                return;
            } else {
                if (value) {
                    setZipCityValue(value);
                    setZipInvalid(false);
                }
            }
        },
        [setZipCityValue],
    );

    const handleSubmitCallback = ({ term, zipCityValue, insuranceValue }) => {
        //clear city suggestion
        setPlaceSuggestions([]);
        submitSearch({
            term,
            zipCityValue,
            insuranceValue,
        });
    };

    return (
        <Theme name={Theme.NAME.PROVIDERS} {...props}>
            <ContentContainer
                className={classnames(styles[`provider-hero`], styles[`provider-hero--${state}`])}
            >
                {breadcrumbs && breadcrumbs.length > 0 && (
                    <Breadcrumb
                        className={styles[`provider-hero__breadcrumb`]}
                        items={breadcrumbs}
                        {...(openPanel ? { inert: '' } : '')}
                        analytics={{ componentName: 'fad_hero', componentTitle: title }}
                    />
                )}
                {state === 'default' && (
                    <ProviderHeroDefault
                        {...props}
                        inputNameTypeahead="term"
                        inputNameZipCity="zip-city"
                        inputNameInsurance="insurance"
                        searchValue={searchValue}
                        setSearchValue={handleSetSearchValue}
                        onSelectionItemChange={handleSelectSearchValue}
                        onSelectionZipCityChange={handleSelectZipCityValue}
                        onSelectionInsuranceChange={handleSelectInsuranceValue}
                        typeaheadItems={typeaheadItems}
                        enableTypeahead={enableTypeahead}
                        zipCityValue={zipCityDefaultValue}
                        setZipCityValue={setZipCityValue}
                        placeSuggestions={placeSuggestions}
                        insuranceValue={insuranceValue}
                        onSubmit={handleSubmitCallback}
                        submitDisabled={isLoading}
                        hideMobileIllustration={hideMobileIllustration}
                        insurancePlans={insurancePlans}
                        handleZipCityInputChange={handleZipCityInputChange}
                        handleAutoSelect={handleAutoSelect}
                        zipInvalid={zipInvalid}
                        setZipInvalid={setZipInvalid}
                    />
                )}
                {state === 'results' && (
                    <ProviderHeroResults
                        {...props}
                        inputNameTypeahead="term"
                        inputNameZipCity="zip-city"
                        searchValue={searchValue}
                        setSearchValue={handleSetSearchValue}
                        onSelectionItemChange={handleSelectSearchValue}
                        onSelectionZipCityChange={handleSelectZipCityValue}
                        typeaheadItems={typeaheadItems}
                        enableTypeahead={enableTypeahead}
                        zipCityValue={zipCityResultsValue}
                        setZipCityValue={setZipCityValue}
                        placeSuggestions={placeSuggestions}
                        onSubmit={handleSubmitCallback}
                        submitDisabled={isLoading}
                        hideMobileIllustration={hideMobileIllustration}
                        handleZipCityInputChange={handleZipCityInputChange}
                        handleAutoSelect={handleAutoSelect}
                        zipInvalid={zipInvalid}
                        setZipInvalid={setZipInvalid}
                    />
                )}
            </ContentContainer>
        </Theme>
    );
};

ProviderHero.propTypes = {
    /**
     * The state of the Provider hero component.
     */
    state: PropTypes.oneOf(['default', 'results']),
    /**
     * The media type to display.
     */
    media: PropTypes.oneOf(['image', 'illustration']),
    /**
     * The image to display
     */
    image: PropTypes.object,
    /**
     * The illustration to display.
     */
    illustration: PropTypes.object,
    /**
     * The breadcrumbs to display.
     */
    breadcrumbs: Breadcrumb.propTypes.items,
};

export default ProviderHero;
