import * as customPropTypes from 'custom-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import { Item } from 'react-stately';

import Typography from '@/design-system/atoms/Typography';

import { CustomSelect } from './components/CustomSelect/CustomSelect';

const Select = React.forwardRef((props, ref) => {
    const {
        label,
        name,
        items = [],
        defaultSelectedKey,
        selectedKey,
        onSelectionChange,
        onSelectionItemChange,
        popoverClassName,
        analytics,
    } = props;

    const handleSelectionChange = React.useCallback(
        (key) => {
            onSelectionChange?.(key);
            onSelectionItemChange?.(items.find((item) => `${item.id}` === key) ?? null);
        },
        [onSelectionChange, onSelectionItemChange, items],
    );

    return (
        <CustomSelect
            ref={ref}
            {...props}
            label={label}
            name={name}
            items={items}
            defaultSelectedKey={defaultSelectedKey}
            selectedKey={selectedKey}
            onSelectionChange={handleSelectionChange}
            popoverClassName={popoverClassName}
            analytics={analytics}
        >
            {items.map((item) => (
                <Item key={item.id ?? item.text} textValue={item.text}>
                    <Typography variant={Typography.VARIANT.T2} lineClamp={1} content={item.text} />
                </Item>
            ))}
        </CustomSelect>
    );
});

Select.propTypes = {
    /**
     * visible label
     */
    label: PropTypes.string,
    /**
     * form control name
     */
    name: PropTypes.string,
    /**
     * List of items
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.string,
        }),
    ),
    /**
     * Default selected key
     */
    defaultSelectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * selected key/ID for controlled component
     */
    selectedkey: PropTypes.string,
    /**
     * This function is triggered every time that the selected ID change
     */
    onSelectionChange: PropTypes.func,
    /**
     * This function is triggered every time that the selected ITEM change
     */
    onSelectionItemChange: PropTypes.func,
    /**
     * If `true` adds a button to open the dropdown and keep it opened
     */
    debug: PropTypes.bool,
    /**
     * Wrapper class name for the Select popover
     */
    popoverClassName: PropTypes.string,
    /**
     * analytics object
     */
    analytics: customPropTypes.analyticsPropType,
};

export default Select;
