import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { IconSliders } from '@/design-system/atoms/Icons/IconSliders';
import { Theme } from '@/design-system/atoms/Theme';
import { ContentCarousel } from '@/design-system/molecules/ContentCarousel';
import * as ContentCarouselStories from '@/design-system/molecules/ContentCarousel/ContentCarousel.stories';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { scrollIntoView } from '@/utils/scrollIntoView';

import { useProviderFilterContext } from '../../contexts/ProviderFilterContext';
import { useProviderSearchContext } from '../../contexts/ProviderSearchContext';
import { useProviderStateContext } from '../../contexts/ProviderStateContext';
import { useProviderUIContext } from '../../contexts/ProviderUIContext';
import { ProviderFilter } from '../ProviderFilter';
import { ProviderResultsList } from '../ProviderResultsList';
import { ProviderToolbar } from '../ProviderToolbar';
import styles from './ProviderMain.module.scss';

const ProviderMain = ({
    analytics,
    schedulingOverrideLabels,
    patientTypeId,
    initialChildren,
    resultsChildren,
    newPatientAppointmentIds,
    affiliationBadgeDictionary,
}) => {
    const state = useProviderStateContext();

    const { filterLabel, filterAriaLabel, patientTypeLabel, patientTypeAriaLabel } =
        useProviderUIContext();
    const breakpoints = useBreakpoint();
    const isMobile = !breakpoints?.large;
    const providerMainRef = useRef();
    const { openPanel, setOpenPanel } = useProviderFilterContext();
    const [footerVisible, setFooterVisible] = useState(false);
    const [totalResults, setTotalResults] = React.useState(null);

    const {
        coveo: {
            controller: { querySummary, breadcrumbManager },
        },
    } = useProviderSearchContext();

    const filterPanelOpen = React.useCallback(() => {
        setOpenPanel(true);
    }, [setOpenPanel]);

    const [filterCount, setFilterCount] = useState();

    useEffect(() => {
        return breadcrumbManager.subscribe(() => {
            const { state } = breadcrumbManager;
            const count = state?.facetBreadcrumbs?.reduce(
                (acc, { values }) => acc + values.length,
                0,
            );
            setFilterCount(count);
        });
    }, [breadcrumbManager]);

    const handleScroll = useCallback(() => {
        const mainTop = providerMainRef.current?.getBoundingClientRect().top;
        const mainBottom = providerMainRef.current?.getBoundingClientRect().bottom;
        const currentScrollY = window.scrollY;

        if (currentScrollY > mainTop && mainBottom >= 0) {
            setFooterVisible(true);
        } else {
            setFooterVisible(false);
        }
    }, [setFooterVisible]);

    React.useEffect(() => {
        return querySummary?.subscribe(() => {
            const { total } = querySummary.state;

            setTotalResults(total);
        });
    }, [querySummary]);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    React.useEffect(() => {
        if (openPanel && isMobile) {
            document.body.style.overflow = 'hidden';
        } else {
            setOpenPanel(false);
            document.body.style.overflow = 'auto';
        }
    }, [openPanel, isMobile, setOpenPanel]);

    const compositeFilterLabel = filterCount ? (
        <div className={styles[`provider-search__filter`]}>
            <span>{filterLabel}</span>
            <span className={classNames(styles[`provider-search__filter--indicator`], 'type-t4')}>
                {filterCount}
            </span>
        </div>
    ) : (
        filterLabel
    );

    if (state === 'default') {
        return <>{initialChildren}</>;
    }

    return (
        <div
            className={styles['provider-search__main']}
            {...(openPanel ? { inert: '' } : '')}
            ref={providerMainRef}
        >
            {state === 'results' ? (
                <>
                    <ContentContainer>
                        <div className={styles['provider-search__tools']}>
                            <ProviderToolbar analytics={analytics} />
                        </div>
                        <Grid>
                            <GridColumn
                                className={styles['provider-search__filters']}
                                colSpan={{ sm: 4, md: 6, lg: 3 }}
                                colStart={{ sm: 1, lg: 1 }}
                            >
                                {!isMobile && <ProviderFilter />}
                            </GridColumn>

                            <GridColumn
                                className={styles['provider-search__views']}
                                colSpan={{ sm: 4, md: 6, lg: 9 }}
                                colStart={{ sm: 1, lg: 4 }}
                            >
                                <ProviderResultsList
                                    patientTypeId={patientTypeId}
                                    analytics={analytics}
                                    schedulingOverrideLabels={schedulingOverrideLabels}
                                    newPatientAppointmentIds={newPatientAppointmentIds}
                                    affiliationBadgeDictionary={affiliationBadgeDictionary}
                                />
                            </GridColumn>
                        </Grid>
                    </ContentContainer>
                    <>{resultsChildren}</>
                    {isMobile && state === 'results' && totalResults > 0 && (
                        <div
                            className={classNames(
                                styles[`provider-search__scroll-footer`],
                                footerVisible && styles[`provider-search__scroll-footer--visible`],
                            )}
                            {...(!footerVisible || openPanel ? { inert: '' } : {})}
                        >
                            <Button
                                label={patientTypeLabel || 'Patient Type'}
                                ariaLabel={patientTypeAriaLabel || 'Select patient type'}
                                buttonStyle={Button.STYLE.SECONDARY}
                                fullWidth={{ md: true }}
                                onClick={() => scrollIntoView(patientTypeId)}
                            />
                            <Button
                                label={compositeFilterLabel || 'Filter'}
                                ariaLabel={filterAriaLabel || 'Filter'}
                                buttonStyle={Button.STYLE.SECONDARY}
                                iconComponent={IconSliders}
                                fullWidth={{ md: true }}
                                onClick={filterPanelOpen}
                            />
                        </div>
                    )}
                </>
            ) : (
                <ContentCarousel
                    {...ContentCarouselStories.default.args}
                    theme={Theme.NAME.DEFAULT}
                />
            )}
        </div>
    );
};

ProviderMain.propTypes = {
    analytics: PropTypes.object,
    schedulingOverrideLabels: PropTypes.shape({
        eyebrow: PropTypes.string,
        description: PropTypes.string,
        newPatientLabel: PropTypes.string,
        existingPatientLabel: PropTypes.string,
        dropdownLabel: PropTypes.string,
    }),
    newPatientAppointmentIds: PropTypes.arrayOf(PropTypes.string),
    affiliationBadgeDictionary: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
};

export default ProviderMain;
