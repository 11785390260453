import { useButton } from '@react-aria/button';
import { HiddenSelect, useSelect } from '@react-aria/select';
import { useSelectState } from '@react-stately/select';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { LazyIconAlertCircle } from '@/design-system/atoms/Icons/IconAlertCircle/Lazy';
import { LazyIconChevronDown } from '@/design-system/atoms/Icons/IconChevronDown/Lazy';
import { LazyIconChevronUp } from '@/design-system/atoms/Icons/IconChevronUp/Lazy';
import { Text } from '@/design-system/atoms/Text';
import { composeRefs } from '@/utils/composeRefs';

import { List } from '../List';
import { Popover } from '../Popover';
import styles from './CustomSelect.module.scss';

export const CustomSelect = forwardRef((props, ref) => {
    const {
        label,
        name,
        isDisabled,
        wrapperClassName,
        buttonClassName,
        popoverClassName,
        debug,
        analytics,
    } = props;

    const state = useSelectState({ ...props });
    const internalRef = useRef(null);
    const selectRef = useRef(null);
    const [selectWidth, setSelectWidth] = React.useState(0);

    const {
        labelProps,
        triggerProps,
        valueProps,
        menuProps,
        errorMessageProps,
        isInvalid,
        validationErrors,
    } = useSelect(props, state, selectRef);

    const { buttonProps } = useButton({ ...props, ...triggerProps });

    const normalizedValidationErrors = [validationErrors].flatMap((v) => v).join(' ');

    useEffect(() => {
        let requestAnimationFrameId = -1;

        const handleSelectResize = () => {
            if (!internalRef.current || !selectRef.current) {
                requestAnimationFrameId = window.requestAnimationFrame(handleSelectResize);
                return;
            }
            const { width } = selectRef.current.getBoundingClientRect();
            setSelectWidth(Math.ceil(width));

            requestAnimationFrameId = window.requestAnimationFrame(handleSelectResize);
        };

        handleSelectResize();

        return () => {
            window.cancelAnimationFrame(requestAnimationFrameId);
        };
    }, []);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        // need to add the css var at root, because popover is rendered using portal
        document.documentElement.style.setProperty(
            '--select-list-item-length',
            `${Math.min(state.collection.size, 6)}`,
        );
    }, [state.collection.size]);

    return (
        <div
            className={classNames(styles['custom-select'], wrapperClassName)}
            ref={internalRef}
            style={{ '--select-width': `${selectWidth}px` }}
            data-invalid={isInvalid ? true : undefined}
        >
            <HiddenSelect
                isDisabled={isDisabled}
                state={state}
                triggerRef={selectRef}
                label={label}
                name={name}
            />
            <div
                className={classNames(
                    styles['custom-select__button-wrapper'],
                    label && state.selectedItem && styles['custom-select__button-wrapper--active'],
                    isDisabled && styles['custom-select__button-wrapper--disabled'],
                )}
            >
                {label && (
                    <div {...labelProps} className={styles['custom-select__label']}>
                        {label}
                    </div>
                )}
                <button
                    className={classNames(styles['custom-select__button'], buttonClassName)}
                    {...buttonProps}
                    ref={composeRefs(ref, selectRef)}
                    disabled={isDisabled}
                    data-trigger={analytics ? analytics?.selector || 'button' : undefined}
                >
                    <span {...valueProps} className={styles['custom-select__value']}>
                        {state.selectedItem ? state.selectedItem.rendered : ''}
                    </span>
                    <span aria-hidden="true" className={styles['custom-select__icon']}>
                        {state.isOpen ? <LazyIconChevronUp /> : <LazyIconChevronDown />}
                    </span>
                </button>
            </div>
            <AnimatePresence>
                {isInvalid && normalizedValidationErrors && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        {...errorMessageProps}
                        className={styles['custom-select__field-error']}
                    >
                        <LazyIconAlertCircle size="small" />
                        <Text
                            tag={Text.TAG.SPAN}
                            variant="t3"
                            content={normalizedValidationErrors}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {state.isOpen && (
                    <Popover
                        state={state}
                        triggerRef={selectRef}
                        placement="bottom start"
                        width={selectWidth}
                        className={popoverClassName}
                    >
                        <List {...menuProps} state={state} />
                    </Popover>
                )}
            </AnimatePresence>

            {debug &&
                ReactDOM.createPortal(
                    <div style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 999999 }}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                state.open();
                            }}
                        >
                            Open/Close DropDown
                        </button>
                    </div>,
                    document.body,
                )}
        </div>
    );
});

CustomSelect.propTypes = {};
