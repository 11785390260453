import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@/design-system/atoms/Button';
import { PlacesTypeahead } from '@/design-system/atoms/Forms/PlacesTypeahead';
import { TextField } from '@/design-system/atoms/Forms/TextField';
import { Typeahead } from '@/design-system/atoms/Forms/Typeahead';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { IconSliders } from '@/design-system/atoms/Icons/IconSliders';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Title } from '@/design-system/atoms/Title';
import IllustrationPlayer from '@/design-system/molecules/IllustrationPlayer';
import { SearchBar } from '@/design-system/molecules/SearchBar';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { trackInteraction } from '@/utils/analytics';
import { locationCheck } from '@/utils/locationCheck';

import { useProviderFilterContext } from '../../../contexts/ProviderFilterContext';
import { useProviderSearchContext } from '../../../contexts/ProviderSearchContext';
import { useProviderUIContext } from '../../../contexts/ProviderUIContext';
import { formValidation } from '../../../utils/formValidation';
import { ProviderFilter } from '../../ProviderFilter';
import styles from '../ProviderHero.module.scss';

const ProviderHeroResults = ({
    inputNameTypeahead,
    inputNameZipCity,
    searchValue,
    setSearchValue,
    onSelectionItemChange,
    onSelectionZipCityChange,
    typeaheadItems,
    typeaheadFixedItem,
    zipCityValue,
    setZipCityValue,
    onSubmit,
    submitDisabled,
    media,
    image,
    illustration,
    googleMaps,
    placeSuggestions,
    handleZipCityInputChange,
    zipInvalid,
    setZipInvalid,
    handleAutoSelect,
}) => {
    const {
        title,
        subtitle,
        searchLabel,
        searchErrorMessage,
        zipLabel,
        zipErrorMessage,
        filterLabel,
        filterAriaLabel,
    } = useProviderUIContext();
    const {
        coveo: {
            controller: { breadcrumbManager },
        },
        enableTypeahead,
        setCoveoAnalytics,
        searchboxId,
    } = useProviderSearchContext();

    const breakpoints = useBreakpoint();
    const isMobile = !breakpoints?.large;
    const searchRef = useRef();
    const zipRef = useRef();
    const [zipSR, setZipSR] = useState(false);
    const [searchValueInvalid, setSearchValueInvalid] = useState(false);
    const [searchValueError, setSearchValueError] = useState();
    const [zipError, setZipError] = useState();
    const { openPanel, setOpenPanel } = useProviderFilterContext();
    const [filterCount, setFilterCount] = useState();

    useEffect(() => {
        return breadcrumbManager.subscribe(() => {
            const { state } = breadcrumbManager;
            const count = state?.facetBreadcrumbs?.reduce(
                (acc, { values }) => acc + values.length,
                0,
            );
            setFilterCount(count);
        });
    }, [breadcrumbManager]);

    const filterPanelOpen = useCallback(() => {
        setOpenPanel(true);
        //analytics
        trackInteraction({
            componentName: 'Provider Hero',
            interactionType: 'cta',
            componentTitle: title,
            selector: 'button',
            actionLabel: '', // TODO: With map CTA gone, what should this be?
            searchTerm: searchValue,
            zipCode: !isNaN(zipCityValue) ? zipCityValue : '',
        });
    }, [setOpenPanel, searchValue, zipCityValue, title]);

    const handleSelectionItemChange = useCallback(
        (item) => {
            if (item && searchboxId) {
                setCoveoAnalytics('querySuggestionClick', {
                    id: searchboxId,
                    suggestion: item?.rawValue,
                });
            }

            onSelectionItemChange?.(item);
        },
        [onSelectionItemChange, setCoveoAnalytics, searchboxId],
    );

    const handleSelectionZipCityChange = useCallback(
        (item) => {
            setCoveoAnalytics('searchboxSubmit', {}, true);
            onSelectionZipCityChange?.(item);
        },
        [onSelectionZipCityChange, setCoveoAnalytics],
    );

    const submitForm = useCallback(
        async (e) => {
            e.preventDefault();

            const { isGeocoded, newLocation } = await locationCheck({
                zipCityValue,
                suggestions: placeSuggestions,
            });

            const [isValid, keys, message] = formValidation(
                {
                    searchValue,
                    zipCityValue: newLocation || zipCityValue,
                    isGeocoded: isGeocoded,
                },
                { searchValue: searchErrorMessage, zipValue: zipErrorMessage },
            );

            if (isValid) {
                setSearchValueInvalid(false);
                setZipInvalid(false);

                setCoveoAnalytics('searchboxSubmit');

                onSubmit({
                    term: searchValue,
                    zipCityValue: newLocation || zipCityValue,
                });

                //analytics
                trackInteraction({
                    eventName: 'search',
                    componentName: 'Provider Hero',
                    interactionType: 'search_results',
                    componentTitle: title,
                    selector: 'search',
                    searchTerm: searchValue,
                    zipCode: !isNaN(zipCityValue) ? zipCityValue : '',
                });

                return;
            }

            if (keys.includes('zipCityValue') && keys.includes('searchValue')) {
                setZipSR(true);
                setZipInvalid(true);
                setSearchValueInvalid(true);
                setZipError(message);
                setSearchValueError(message);
                searchRef.current.focus();
            } else if (keys.includes('zipCityValue')) {
                setSearchValueInvalid(false);
                setZipInvalid(true);
                setZipError(message);
                zipRef.current.focus();
            }
        },
        [
            placeSuggestions,
            onSubmit,
            searchErrorMessage,
            searchValue,
            setCoveoAnalytics,
            title,
            zipErrorMessage,
            zipCityValue,
            setZipInvalid,
        ],
    );

    const compositeFilterLabel = filterCount ? (
        <div className={styles[`provider-hero__filter`]}>
            <span>{filterLabel}</span>
            <span className={classNames(styles[`provider-hero__filter--indicator`], 'type-t4')}>
                {filterCount}
            </span>
        </div>
    ) : (
        filterLabel
    );

    return (
        <>
            <Grid
                col={{ sm: 4, md: 6, lg: 12 }}
                className={styles[`provider-hero__grid`]}
                {...(openPanel ? { inert: '' } : '')}
            >
                <GridColumn
                    colSpan={{ sm: 4, md: 6, lg: 7 }}
                    className={styles[`provider-hero__content`]}
                >
                    <Title
                        variant={Title.VARIANT.H2}
                        tag={Title.TAG.H1}
                        content={title}
                        className={styles[`provider-hero__title`]}
                    />
                    {subtitle && (
                        <Text
                            variant={Text.VARIANT.T2}
                            tag={Text.TAG.P}
                            content={subtitle}
                            className={styles[`provider-hero__subtitle`]}
                        />
                    )}

                    <SearchBar submitHandler={submitForm} data-trigger="search">
                        <SearchBar.Inputs>
                            {enableTypeahead ? (
                                <Typeahead
                                    ref={searchRef}
                                    name={inputNameTypeahead}
                                    label={searchLabel}
                                    variant={TextField.VARIANT.NO_BORDER}
                                    buttonProps={undefined}
                                    className={styles[`provider-hero__typeahead`]}
                                    items={typeaheadItems}
                                    fixedItem={typeaheadFixedItem}
                                    inputValue={searchValue}
                                    onInputChange={setSearchValue}
                                    onSelectionItemChange={(item) =>
                                        handleSelectionItemChange(item)
                                    }
                                    isInvalid={searchValueInvalid}
                                    validationErrors={searchValueError}
                                />
                            ) : (
                                <TextField
                                    ref={searchRef}
                                    name={inputNameTypeahead}
                                    label={searchLabel}
                                    variant={TextField.VARIANT.NO_BORDER}
                                    buttonProps={undefined}
                                    className={styles[`provider-hero__typeahead`]}
                                    value={searchValue}
                                    onChange={setSearchValue}
                                    isInvalid={searchValueInvalid}
                                    validationErrors={searchValueError}
                                />
                            )}

                            <PlacesTypeahead
                                ref={zipRef}
                                label={zipLabel}
                                inputNameTypeahead={inputNameZipCity}
                                inputValue={zipCityValue}
                                onInputChange={handleZipCityInputChange}
                                onSelectionItemChange={handleSelectionZipCityChange}
                                onGeolocationSuccess={setZipCityValue}
                                isInvalid={zipInvalid}
                                validationErrors={zipError}
                                className={styles[`provider-hero__typeahead`]}
                                srError={zipSR}
                                googleMaps={googleMaps}
                                autoSelect={{ enabled: true, callback: handleAutoSelect }}
                            />
                        </SearchBar.Inputs>
                        <SearchBar.SubmitButton
                            type="submit"
                            disabled={submitDisabled}
                            className={styles[`provider-hero__button`]}
                            data-submit-type="provider-search"
                        />
                        <SearchBar.MobileFooter>
                            <Button
                                label={compositeFilterLabel}
                                ariaLabel={filterAriaLabel}
                                buttonStyle={Button.STYLE.SECONDARY}
                                iconComponent={IconSliders}
                                onClick={filterPanelOpen}
                                className={styles[`provider-hero__button`]}
                            />
                        </SearchBar.MobileFooter>
                    </SearchBar>
                </GridColumn>
                {!isMobile && (
                    <GridColumn
                        colSpan={{ sm: 4, md: 6, lg: 4 }}
                        colStart={{ sm: 1, md: 1, lg: 10 }}
                        className={styles[`provider-hero__illustration`]}
                    >
                        {media === 'image' && image && <Image {...image} />}
                        {media === 'illustration' && illustration?.scene && illustration?.title && (
                            <IllustrationPlayer {...illustration} />
                        )}
                    </GridColumn>
                )}
            </Grid>
            {isMobile && <ProviderFilter />}
        </>
    );
};

ProviderHeroResults.defaultProps = {
    filterLabel: 'Filter',
    filterAriaLabel: 'Filter',
};

ProviderHeroResults.propTypes = {
    /**
     * The title of the location hero component.
     */
    title: PropTypes.string,
    /**
     * The subtitle of the location hero component.
     */
    subtitle: PropTypes.string,
    /**
     * The label for the search input.
     */
    searchLabel: PropTypes.string,
    /**
     * The label for the zip input.
     */
    zipLabel: PropTypes.string,
    /**
     * The media type to display.
     */
    media: PropTypes.oneOf(['image', 'illustration']),
    /**
     * The image to display
     */
    image: PropTypes.object,
    /**
     * The illustration to display.
     */
    illustration: PropTypes.object,
    /**
     * The label for the filter button.
     */
    filterLabel: PropTypes.string,
    /**
     * The aria label for the filter button.
     */
    filterAriaLabel: PropTypes.string,
};

export default ProviderHeroResults;
