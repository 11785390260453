const zipRegex = RegExp(`^[0-9]{5}(?:-[0-9]{4})?$`);

export const formValidation = ({ searchValue, zipCityValue, isGeocoded }, errorMessage = {}) => {
    if (searchValue == null) {
        if (zipCityValue && !isNaN(zipCityValue) && !zipRegex.test(zipCityValue)) {
            return [
                false,
                ['zipCityValue'],
                errorMessage.zipValue ?? 'Please enter a valid ZIP code',
            ];
        } else if (zipCityValue && isNaN(zipCityValue) && !isGeocoded) {
            return [
                false,
                ['zipCityValue'],
                errorMessage.zipCityValue ?? 'Please enter a valid location',
            ];
        }

        return [true];
    }

    if (zipCityValue && !isNaN(zipCityValue) && !zipRegex.test(zipCityValue)) {
        return [false, ['zipCityValue'], errorMessage.zipValue ?? 'Please enter a valid ZIP code'];
    } else if (zipCityValue && isNaN(zipCityValue) && !isGeocoded) {
        return [
            false,
            ['zipCityValue'],
            errorMessage.zipCityValue ?? 'Please enter a valid location',
        ];
    }

    return [true];
};
