import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Popover.module.scss';

export const Popover = (props) => {
    const { state, children, width, className } = props;
    const popoverRef = React.useRef(null);

    const { popoverProps } = usePopover(
        {
            ...props,
            popoverRef,
        },
        state,
    );

    return (
        <Overlay>
            <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                {...popoverProps}
                ref={popoverRef}
                className={classNames(styles['popover'], className)}
                style={{ width }}
            >
                <DismissButton onDismiss={() => state.close()} />
                {children}
                <DismissButton onDismiss={() => state.close()} />
            </motion.div>
        </Overlay>
    );
};

Popover.propTypes = {
    /**
     * state from `useSelectState`
     */
    state: PropTypes.object,
    /**
     * select field width, so the popover will have the same width
     */
    width: PropTypes.number,
};
