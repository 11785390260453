import React from 'react';

import { useSearchParams } from '@/hooks/useSearchParams';

import {
    mapUrlParamsToQuery,
    mapUrlParamsToSort,
    urlParamFilterNames,
} from './ProviderSearchContext';

const ProviderFilterContext = React.createContext({});

export const ProviderFilterContextProvider = ({ children }) => {
    const [openPanel, setOpenPanel] = React.useState(false);
    const [tempFilter, setTempFilter] = React.useState({});

    const searchParams = useSearchParams();

    const searchParamToTempFilter = React.useCallback(() => {
        const newTempFilter = {};

        const singleValueNames = [
            ...Object.keys(mapUrlParamsToQuery),
            ...Object.keys(mapUrlParamsToSort),
        ];

        for (const name of singleValueNames) {
            const value = searchParams.get(name);
            if (value) {
                newTempFilter[name] = value;
            }
        }

        for (const name of urlParamFilterNames) {
            const value = searchParams.getAll(name)?.filter(Boolean);
            if (value && value.length > 0) {
                newTempFilter[name] = value;
            }
        }

        setTempFilter(newTempFilter);
    }, [searchParams]);

    React.useEffect(() => {
        searchParamToTempFilter();
    }, [searchParamToTempFilter]);

    return (
        <ProviderFilterContext.Provider
            value={{
                openPanel,
                setOpenPanel,
                tempFilter,
                setTempFilter,
                searchParamToTempFilter,
            }}
        >
            {children}
        </ProviderFilterContext.Provider>
    );
};

export const useProviderFilterContext = () => {
    const context = React.useContext(ProviderFilterContext);

    if (context == null) {
        throw new Error(`'useProviderFilterContext' needs to be within 'ProviderFilterContext'`);
    }

    return context;
};
