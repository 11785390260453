import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { Checkbox } from '@/design-system/atoms/Forms/Checkbox';
import { Text } from '@/design-system/atoms/Text';
import { useSearchParams } from '@/hooks/useSearchParams';

import {
    useProviderSearchContext,
    useProviderSearchZipCityContext,
} from '../../contexts/ProviderSearchContext';
import styles from './ProviderToolbar.module.scss';

/**
 * The Provider Toolbar is a component that displays the total number of results and the search radius.
 */
const ProviderToolbar = ({ analytics }) => {
    const searchParams = useSearchParams();

    const {
        coveo: {
            controller: { didYouMean, querySummary },
        },
        handleSingleFilter,
        setCoveoAnalytics,
        handleApplyCorrection,
        queryCorrected,
    } = useProviderSearchContext();
    const { zipCode, city } = useProviderSearchZipCityContext();

    const [totalResults, setTotalResults] = React.useState(null);
    const [distance, setDistance] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [hasError, setHasError] = React.useState(false);

    const [acceptingNew, setAcceptingNew] = React.useState();
    const [schedule, setSchedule] = React.useState();

    React.useEffect(() => {
        return querySummary.subscribe(() => {
            const { total, query, hasError } = querySummary.state;

            if (total === 0 && didYouMean?.state?.queryCorrection?.correctedQuery) {
                handleApplyCorrection(true);
            }

            setTotalResults(total);

            setHasError(hasError);

            // Remove any additional coveo query parameters from the string if they exist
            const parsedSearchTerm =
                query
                    ?.replace(/@mydistanceinmiles\s*<=\s*\d+/g, '')
                    ?.split('AND')?.[0]
                    ?.trim() || '';
            setSearchTerm(parsedSearchTerm);
        });
    }, [didYouMean, handleApplyCorrection, querySummary]);

    React.useEffect(() => {
        const searchParamDistance = searchParams.get('distance');
        const searchParamNew = searchParams.get('new');
        const searchParamSchedule = searchParams.get('schedule');

        setDistance(searchParamDistance ?? '');
        setAcceptingNew(searchParamNew ?? false);
        setSchedule(searchParamSchedule ?? false);
    }, [searchParams]);

    /**
     * Handle checkbox toggle
     * @param {string} name - The input name
     * @param {string} label - The input label for analytics
     * @param {boolean} isSelected - The new status of the input
     * @returns {void}
     */
    const handleCheckbox = (name, label, isSelected) => {
        setCoveoAnalytics(isSelected ? 'staticFilterSelect' : 'staticFilterDeselect', {
            staticFilterId: name,
            staticFilterValue: {
                caption: label,
                expression: isSelected ? `${name}=true` : '',
            },
        });

        handleSingleFilter(
            { [name]: isSelected },
            {
                ...analytics,
                componentName: 'fad_results_info_bar',
                eventName: 'search',
                actionLabel: label,
                allFilters: '',
                searchTerm: searchTerm || '',
                zipCode: zipCode || '',
            },
        );
    };

    return (
        <section className={styles[`provider-toolbar`]}>
            <div>
                {totalResults > 0 ? (
                    <>
                        {didYouMean?.state?.queryCorrection?.correctedQuery || queryCorrected ? (
                            <div className={styles['provider-toolbar__additional-content']}>
                                {didYouMean?.state?.queryCorrection?.correctedQuery && (
                                    <>
                                        <Text
                                            tag={Text.TAG.P}
                                            variant={Text.VARIANT.T2}
                                            content="Did you mean?"
                                            className={
                                                styles['provider-toolbar__additional-content-text']
                                            }
                                        />
                                        <Button
                                            buttonStyle={Button.STYLE.BREADCRUMB}
                                            label={didYouMean.state.queryCorrection.correctedQuery}
                                            ariaLabel={`Search for ${didYouMean.state.queryCorrection.correctedQuery}`}
                                            className={
                                                styles[
                                                    'provider-toolbar__additional-content-button'
                                                ]
                                            }
                                            onClick={() => handleApplyCorrection(false)}
                                        />
                                    </>
                                )}
                                {queryCorrected && (
                                    <>
                                        <Text
                                            tag={Text.TAG.P}
                                            variant={Text.VARIANT.T2}
                                            content="Showing results for"
                                            className={
                                                styles['provider-toolbar__additional-content-text']
                                            }
                                        />
                                        <Text
                                            tag={Text.TAG.P}
                                            variant={Text.VARIANT.T2}
                                            content={searchTerm}
                                            className={
                                                styles[
                                                    'provider-toolbar__additional-autocorrect-text'
                                                ]
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        <>
                            <Text
                                tag={Text.TAG.P}
                                variant={Text.VARIANT.T2}
                                content={
                                    <>
                                        {totalResults} results
                                        {searchTerm && (
                                            <span>
                                                {` for `}
                                                <span class="type-t1b">{searchTerm}</span>
                                            </span>
                                        )}
                                        {distance && (
                                            <span>
                                                {` within ${distance} miles`}
                                                {(city || zipCode) && (
                                                    <span>
                                                        {` of `}
                                                        <span class="type-t1b">
                                                            {city || zipCode}
                                                        </span>
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                        {!distance && (city || zipCode) && (
                                            <span>
                                                {` near `}
                                                <span class="type-t1b">{city || zipCode}</span>
                                            </span>
                                        )}
                                    </>
                                }
                            />
                        </>
                    </>
                ) : (
                    !hasError && (
                        <span>
                            0 results{' '}
                            {city || zipCode
                                ? `near ${city || zipCode}`
                                : searchTerm
                                  ? `for ${searchTerm}`
                                  : ''}
                        </span>
                    )
                )}
            </div>
            <div className={styles[`provider-toolbar__toggles`]}>
                <Checkbox
                    name="new"
                    label="Accepting New Patients"
                    onChange={(isSelected) =>
                        handleCheckbox('new', 'Accepting New Patients', isSelected)
                    }
                    defaultSelected={false}
                    isSelected={acceptingNew}
                />
                <Checkbox
                    name="schedule"
                    label="Schedule Online"
                    onChange={(isSelected) =>
                        handleCheckbox('schedule', 'Schedule Online', isSelected)
                    }
                    defaultSelected={false}
                    isSelected={schedule}
                />
            </div>
        </section>
    );
};

export default ProviderToolbar;
