import { useFormValidation } from '@react-aria/form';
import { useInteractionModality } from '@react-aria/interactions';
import { useFormReset } from '@react-aria/utils';
import { useVisuallyHidden } from '@react-aria/visually-hidden';

import { selectData } from '../../hooks/useMultiSelect';

export const useHiddenMultiSelect = (props, state, triggerRef) => {
    let data = selectData.get(state) || {};
    let { name = data.name, isDisabled = data.isDisabled } = props;
    let { validationBehavior, isRequired } = data;
    const modality = useInteractionModality();
    const { visuallyHiddenProps } = useVisuallyHidden();
    useFormReset(props.selectRef, state.selectedKey, state.setSelectedKey);
    useFormValidation(
        {
            validationBehavior,
            focus: () => triggerRef.current.focus(),
        },
        state,
        props.selectRef,
    );
    return {
        containerProps: {
            ...visuallyHiddenProps,
            'aria-hidden': true,
        },
        inputProps: {
            type: 'text',
            tabIndex: modality == null || state.isFocused || state.isOpen ? -1 : 0,
            style: { fontSize: 16 },
            onFocus: () => triggerRef.current?.focus(),
            disabled: isDisabled,
        },
        selectProps: {
            tabIndex: -1,
            disabled: isDisabled,
            required: validationBehavior === 'native' && isRequired,
            name,
            size: state.collection.size,
            selectedoptions: [...state.collection].map((item) => ({ value: item.key })),
            onChange: (event) =>
                state.setSelectedKeys(
                    new Set([...event.target.selectedOptions].map((option) => option.value)),
                ),
        },
    };
};
export const HiddenMultiSelect = (props) => {
    const { state, triggerRef, label, name, isDisabled, singleSelectEnabled } = props;
    const { containerProps, inputProps, selectProps } = useHiddenMultiSelect(
        props,
        state,
        triggerRef,
    );

    if (state.collection.size <= 300) {
        return (
            <div {...containerProps}>
                <input {...inputProps} />
                <label>
                    {label}
                    <select {...{ multiple: !singleSelectEnabled }} {...selectProps}>
                        <option />
                        {[...state.collection.getKeys()].map((key) => {
                            const item = state.collection.getItem(key);
                            if (item.type === 'item') {
                                return (
                                    <option key={item.key} value={item.value}>
                                        {item.textValue}
                                    </option>
                                );
                            }
                            return null;
                        })}
                    </select>
                </label>
            </div>
        );
    }

    if (name) {
        return (
            <>
                {[...state.selectedKeys].map((key) => (
                    <input
                        key={key}
                        multiple
                        autoComplete={selectProps.autoComplete}
                        disabled={isDisabled}
                        name={name}
                        type="hidden"
                        value={key}
                    />
                ))}
            </>
        );
    }

    return null;
};
