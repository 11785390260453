/**
 * Scroll component into view
 * @param {string} id
 * @returns {void}
 */
export const scrollIntoView = (id, behavior, block) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: behavior || 'smooth', block: block || 'start' });
    }
};
