import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { Select } from '@/design-system/atoms/Forms/Select';
import { Text } from '@/design-system/atoms/Text';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { trackInteraction } from '@/utils/analytics';

import {
    useProviderSearchContext,
    useProviderSearchZipCityContext,
} from '../../contexts/ProviderSearchContext';
import styles from './ProviderScheduling.module.scss';

const ITEMS = [
    { id: 'new', text: 'New Patient' },
    { id: 'existing', text: 'Existing Patient' },
];

/**
 * The Provider Scheduling component presents a selector that lets the user specify
 * if they're a new or existing patient
 */
const ProviderScheduling = ({ id, schedulingOverrideLabels, analytics, searchValue }) => {
    const dropdownRef = React.useRef();
    const breakpoint = useBreakpoint();
    const isMobile = !breakpoint?.large;
    const { handleScheduling, patientType } = useProviderSearchContext();
    const { zipCode } = useProviderSearchZipCityContext();

    const { eyebrow, description, newPatientLabel, existingPatientLabel, dropdownLabel } =
        schedulingOverrideLabels;

    const analyticsObj = React.useMemo(() => {
        return {
            ...analytics,
            componentName: 'fad_results_scheduling_module_controls',
            zipCode: zipCode || '',
            searchTerm: searchValue || '',
            interactionType: 'fad_result_filter',
            eventName: 'search',
        };
    }, [analytics, searchValue, zipCode]);

    /**
     * Handle selecting a patient type
     * @param {string} type The selected patient type
     * @returns {void}
     */
    const handlePatientTypeSelection = React.useCallback(
        (type) => {
            if (handleScheduling) handleScheduling(type);

            //analytics
            const optionLabel = ITEMS?.find((item) => item.id === type)?.text;
            trackInteraction({ ...analyticsObj, actionLabel: optionLabel });
        },
        [handleScheduling, analyticsObj],
    );

    return (
        <section className={styles[`provider-scheduling`]} id={id}>
            <div className={styles[`provider-scheduling__description`]}>
                <Text tag={Text.TAG.SPAN} variant={Text.VARIANT.T4} content={eyebrow} />
                <Text tag={Text.TAG.SPAN} variant={Text.VARIANT.T2} content={description} />
            </div>
            {isMobile ? (
                <Select
                    ref={dropdownRef}
                    label={dropdownLabel}
                    items={ITEMS}
                    defaultSelectedKey={patientType}
                    onSelectionChange={handlePatientTypeSelection}
                />
            ) : (
                <div className={styles[`provider-scheduling__controls`]} role="group">
                    <Button
                        className={classnames(
                            styles[`provider-scheduling__button`],
                            patientType === 'new' &&
                                styles[`provider-scheduling__button--selected`],
                        )}
                        onClick={() => handlePatientTypeSelection('new')}
                        label={newPatientLabel}
                        aria-label={`Select ${newPatientLabel}`}
                        buttonStyle={Button.STYLE.BREADCRUMB}
                        analytics={analyticsObj}
                    />
                    <Button
                        className={classnames(
                            styles[`provider-scheduling__button`],
                            patientType === 'existing' &&
                                styles[`provider-scheduling__button--selected`],
                        )}
                        onClick={() => handlePatientTypeSelection('existing')}
                        label={existingPatientLabel}
                        aria-label={`Select ${existingPatientLabel}`}
                        buttonStyle={Button.STYLE.BREADCRUMB}
                        analytics={analyticsObj}
                    />
                </div>
            )}
        </section>
    );
};

ProviderScheduling.defaultProps = {
    id: 'patient-type',
    schedulingOverrideLabels: {
        eyebrow: 'Schedule Online',
        description: 'Are you a new or existing patient?',
        newPatientLabel: 'New Patient',
        existingPatientLabel: 'Existing Patient',
        dropdownLabel: 'Appointment Type',
    },
};

ProviderScheduling.propTypes = {
    id: PropTypes.string,
    schedulingOverrideLabels: PropTypes.shape({
        eyebrow: PropTypes.string,
        description: PropTypes.string,
        newPatientLabel: PropTypes.string,
        existingPatientLabel: PropTypes.string,
        dropdownLabel: PropTypes.string,
    }),
    searchValue: PropTypes.string,
    analytics: PropTypes.object,
};

export default ProviderScheduling;
