import { useButton } from '@react-aria/button';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { LazyIconAlertCircle } from '@/design-system/atoms/Icons/IconAlertCircle/Lazy';
import { LazyIconChevronDown } from '@/design-system/atoms/Icons/IconChevronDown/Lazy';
import { LazyIconChevronUp } from '@/design-system/atoms/Icons/IconChevronUp/Lazy';
import { Text } from '@/design-system/atoms/Text';
import { composeRefs } from '@/utils/composeRefs';

import { useMultiSelect } from '../../hooks';
import MultiSelect from '../../MultiSelect';
import { useMultiSelectState } from '../../state';
import { HiddenMultiSelect } from '../HiddenMultiSelect';
import { List } from '../List';
import { Popover } from '../Popover';
import styles from './MultiSelect.module.scss';

const CustomMultiSelect = React.forwardRef((props, forwardedRef) => {
    const {
        isDisabled,
        label,
        searchboxLabel,
        name,
        wrapperClassName,
        buttonClassName,
        popoverClassName,
        variant,
        openStateIcon,
        closeStateIcon,
        analytics,
        singleSelectEnabled,
    } = props;
    const state = useMultiSelectState({ ...props });
    const containerRef = React.useRef(null);
    const selectRef = React.useRef(null);
    const listBoxRef = React.useRef(null);
    const popoverRef = React.useRef(null);
    const [selectWidth, setSelectWidth] = React.useState(0);
    const [optionsSize, setOptionsSize] = React.useState(state.collection.size || 0);

    const {
        labelProps,
        triggerProps,
        valueProps,
        menuProps,
        errorMessageProps,
        isInvalid,
        validationErrors,
    } = useMultiSelect(props, state, selectRef);

    const { buttonProps } = useButton({ ...props, ...triggerProps }, selectRef);

    const normalizedValidationErrors = [validationErrors].flatMap((v) => v).join(' ');

    React.useEffect(() => {
        let requestAnimationFrameId = -1;

        const handleSelectResize = () => {
            if (!containerRef.current || !selectRef.current) {
                requestAnimationFrameId = window.requestAnimationFrame(handleSelectResize);
                return;
            }
            const { width } = selectRef.current.getBoundingClientRect();
            setSelectWidth(Math.ceil(width));

            requestAnimationFrameId = window.requestAnimationFrame(handleSelectResize);
        };

        handleSelectResize();

        return () => {
            window.cancelAnimationFrame(requestAnimationFrameId);
        };
    }, []);

    React.useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        // need to add the css var at root, because popover is rendered using portal
        document.documentElement.style.setProperty(
            '--select-list-item-length',
            `${Math.min(optionsSize, 6)}`,
        );
    }, [optionsSize]);

    return (
        <div
            className={classNames(styles['multi-select'], wrapperClassName)}
            ref={containerRef}
            style={{ '--select-width': `${selectWidth}px` }}
            data-invalid={isInvalid ? true : undefined}
        >
            <HiddenMultiSelect
                isDisabled={isDisabled}
                label={label}
                name={name}
                state={state}
                triggerRef={selectRef}
                singleSelectEnabled={singleSelectEnabled}
            />
            <div
                className={classNames(
                    styles['multi-select__button-wrapper'],
                    label &&
                        state.selectedItems?.length > 0 &&
                        styles['multi-select__button-wrapper--active'],
                    isDisabled && styles['multi-select__button-wrapper--disabled'],
                )}
            >
                {label && (
                    <div {...labelProps} className={styles['multi-select__label']}>
                        {label}
                    </div>
                )}
                <button
                    className={classNames(
                        styles['multi-select__button'],
                        buttonClassName,
                        variant === MultiSelect.VARIANT.BOTTOM_BORDER &&
                            styles['multi-select__button--bottom-border'],
                        variant === MultiSelect.VARIANT.NO_BORDER &&
                            styles['multi-select__button--no-border'],
                    )}
                    {...buttonProps}
                    ref={composeRefs(forwardedRef, selectRef)}
                    disabled={isDisabled}
                    data-trigger={analytics ? analytics?.selector || 'button' : undefined}
                >
                    <span {...valueProps} className={styles['multi-select__value']}>
                        <Text
                            tag={Text.TAG.SPAN}
                            variant={Text.VARIANT.T1}
                            lineClamp={{ sm: 1, md: 1, lg: 1 }}
                            content={state?.selectedItems
                                ?.map((item) => item?.textValue)
                                ?.join(', ')}
                        />
                    </span>
                    <span aria-hidden="true" className={styles['multi-select__icon']}>
                        {state.isOpen
                            ? openStateIcon ?? <LazyIconChevronUp />
                            : closeStateIcon ?? openStateIcon ?? <LazyIconChevronDown />}
                    </span>
                </button>
            </div>
            <AnimatePresence>
                {isInvalid && normalizedValidationErrors && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        {...errorMessageProps}
                        className={styles['multi-select__field-error']}
                    >
                        <LazyIconAlertCircle size="small" />
                        <Text
                            tag={Text.TAG.SPAN}
                            variant="t3"
                            content={normalizedValidationErrors}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {state.isOpen && (
                    <Popover
                        ref={popoverRef}
                        state={state}
                        triggerRef={selectRef}
                        placement="bottom start"
                        width={selectWidth}
                        className={popoverClassName}
                    >
                        <List
                            {...menuProps}
                            ref={listBoxRef}
                            state={state}
                            searchboxLabel={searchboxLabel}
                            setOptionsSize={setOptionsSize}
                            triggerRef={selectRef}
                        />
                    </Popover>
                )}
            </AnimatePresence>
        </div>
    );
});

export default CustomMultiSelect;
