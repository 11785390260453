import { useListBox, useOption } from '@react-aria/listbox';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './List.module.scss';

export const ListItem = (props) => {
    const { item, state } = props;
    const ref = React.useRef(null);
    const { optionProps, isSelected, isFocused, isDisabled } = useOption(
        {
            key: item.key,
        },
        state,
        ref,
    );

    return (
        <li
            {...optionProps}
            ref={ref}
            className={classNames(
                styles['list-item'],
                isSelected && styles['list-item--selected'],
                isFocused && styles['list-item--focused'],
                isDisabled && styles['list-item--disabled'],
            )}
        >
            {item.rendered}
        </li>
    );
};

export const List = (props) => {
    const { state } = props;
    const ref = React.useRef(null);
    const { listBoxProps } = useListBox(props, state, ref);

    return (
        <div {...listBoxProps} ref={ref} className={styles['list-wrapper']} aria-live="polite">
            <ul role="none" className={styles['list']}>
                {[...state.collection].map((item) => (
                    <ListItem key={item.key} item={item} state={state} />
                ))}
            </ul>
        </div>
    );
};
List.propTypes = {
    /**
     * state from `useSelectState`
     */
    state: PropTypes.object,
};
