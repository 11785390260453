import { buildCriterionExpression } from '@coveo/headless';
import React from 'react';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useSearchParams } from '@/hooks/useSearchParams';

import { useProviderSearchContext } from '../../contexts/ProviderSearchContext';
import { ProviderFacet } from './ProviderFacet';
import { ProviderFilterDesktop } from './ProviderFilterDesktop';
import { ProviderFilterMobile } from './ProviderFilterMobile';

const ProviderFilter = ({ ...props }) => {
    const breakpoint = useBreakpoint();
    const isMobile = !breakpoint?.large;

    const {
        coveo: {
            controller: { sort, facet },
        },
        handleSingleFilter,
        handleFilterResetAll,
        sortCriteria,
        setCoveoAnalytics,
        disableNextAvailableSort,
    } = useProviderSearchContext();

    const [sortState, setSortState] = React.useState(sort.state);

    const [specialtyState, setSpecialtyState] = React.useState();
    const [insuranceState, setInsuranceState] = React.useState();
    const [languageState, setLanguageState] = React.useState();
    const [genderState, setGenderState] = React.useState();
    const [typeState, setTypeState] = React.useState();
    const [visitState, setVisitState] = React.useState();
    const [affiliationState, setAffiliationState] = React.useState();
    const [specialtyServiceState, setSpecialtyServiceState] = React.useState();

    const getCriterionFromName = React.useCallback(
        (name) => sortCriteria.find(([criterionName]) => criterionName === name),
        [sortCriteria],
    );

    const getCurrentCriterion = React.useCallback(
        () =>
            sortCriteria.find(
                ([, criterion]) => sortState.sortCriteria === buildCriterionExpression(criterion),
            ),
        [sortCriteria, sortState.sortCriteria],
    );

    React.useEffect(() => sort.subscribe(() => setSortState(sort.state)), [sort]);

    React.useEffect(() => {
        const unsubscribe = facet.specialty.subscribe(() => {
            setSpecialtyState(facet.specialty.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.specialty]);

    React.useEffect(() => {
        const unsubscribe = facet.insurance.subscribe(() => {
            setInsuranceState(facet.insurance.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.insurance]);

    React.useEffect(() => {
        const unsubscribe = facet.language.subscribe(() => {
            setLanguageState(facet.language.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.language]);

    React.useEffect(() => {
        const unsubscribe = facet.gender.subscribe(() => {
            setGenderState(facet.gender.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.gender]);

    React.useEffect(() => {
        const unsubscribe = facet.type.subscribe(() => {
            setTypeState(facet.type.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.type]);

    React.useEffect(() => {
        const unsubscribe = facet.visit.subscribe(() => {
            setVisitState(facet.visit.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.visit]);

    React.useEffect(() => {
        const unsubscribe = facet.affiliation.subscribe(() => {
            setAffiliationState(facet.affiliation.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.affiliation]);

    React.useEffect(() => {
        const unsubscribe = facet.specialtyservice.subscribe(() => {
            setSpecialtyServiceState(facet.specialtyservice.state);
        });
        return () => {
            unsubscribe();
        };
    }, [facet.specialtyservice]);

    const handleSort = React.useCallback(
        (value) => {
            setCoveoAnalytics('resultsSort');
            handleSingleFilter(
                { sort: getCriterionFromName(value)[0] },
                { componentTitle: 'Sort By', actionLabel: value },
            );
        },
        [getCriterionFromName, handleSingleFilter, setCoveoAnalytics],
    );

    const searchParams = useSearchParams();
    const disabledDistanceOptions = !searchParams.has('zip-code') && !searchParams.has('city');
    const urlDistance = searchParams.get('distance') ?? '';

    const [distance, setDistance] = React.useState(urlDistance);
    React.useEffect(() => {
        setDistance(urlDistance);
    }, [urlDistance]);

    const accordionItems = React.useMemo(
        () => [
            ...(specialtyState?.values?.length > 0
                ? [
                      {
                          id: 'specialty',
                          title: 'Specialty',
                          titleIndicator: <ProviderFacet.Badge fieldName={'specialties'} />,
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'specialty'}
                                  variant={'searchable-checkbox'}
                                  controller={facet.specialty}
                                  analytics={{
                                      componentTitle: 'Specialty',
                                  }}
                                  fixedOption={'primary care'}
                              />
                          ),
                      },
                  ]
                : []),
            ...(insuranceState?.values?.length > 0
                ? [
                      {
                          id: 'insurance',
                          title: 'Insurance Carriers',
                          titleIndicator: <ProviderFacet.Badge fieldName={'insuranceaccepted'} />,
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'insurance'}
                                  variant={'searchable-checkbox'}
                                  controller={facet.insurance}
                                  title={
                                      'Please contact the practice to confirm your health plan is accepted.'
                                  }
                                  analytics={{
                                      componentTitle: 'Insurance',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
            ...(languageState?.values?.length > 0
                ? [
                      {
                          id: 'language',
                          title: 'Language',
                          titleIndicator: <ProviderFacet.Badge fieldName={'languages'} />,
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'language'}
                                  variant={'searchable-radio'}
                                  controller={facet.language}
                                  analytics={{
                                      componentTitle: 'Language',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
            ...(genderState?.values?.length > 0
                ? [
                      {
                          id: 'gender',
                          title: 'Gender',
                          titleIndicator: <ProviderFacet.Badge fieldName={'gender'} />,
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'gender'}
                                  variant={'checkbox'}
                                  controller={facet.gender}
                                  analytics={{
                                      componentTitle: 'Gender',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
            ...(typeState?.values?.length > 0
                ? [
                      {
                          id: 'type',
                          title: 'Provider Type',
                          titleIndicator: <ProviderFacet.Badge fieldName={'providertype'} />,
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'type'}
                                  variant={'checkbox'}
                                  controller={facet.type}
                                  analytics={{
                                      componentTitle: 'Provider Type',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
            ...(visitState?.values?.length > 0
                ? [
                      {
                          id: 'visit',
                          title: 'Visit Type',
                          titleIndicator: (
                              <ProviderFacet.Badge fieldName={'additionalvisittypes'} />
                          ),
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'visit'}
                                  variant={'checkbox'}
                                  controller={facet.visit}
                                  analytics={{
                                      componentTitle: 'Visit Type',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
            ...(affiliationState?.values?.length > 0
                ? [
                      {
                          id: 'affiliation',
                          title: 'Affiliations',
                          titleIndicator: (
                              <ProviderFacet.Badge fieldName={'hospitalaffiliations'} />
                          ),
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'affiliation'}
                                  variant={'searchable-checkbox'}
                                  controller={facet.affiliation}
                                  analytics={{
                                      componentTitle: 'Affiliations',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
            ...(specialtyServiceState?.values?.length > 0
                ? [
                      {
                          id: 'specialtyservice',
                          title: 'Specialty Services',
                          titleIndicator: <ProviderFacet.Badge fieldName={'specialtyservice'} />,
                          children: (
                              <ProviderFacet.Root
                                  urlParamName={'specialtyservice'}
                                  variant={'checkbox'}
                                  controller={facet.specialtyservice}
                                  analytics={{
                                      componentTitle: 'Specialty Services',
                                  }}
                              />
                          ),
                      },
                  ]
                : []),
        ],
        [
            facet,
            specialtyState,
            insuranceState,
            languageState,
            genderState,
            typeState,
            visitState,
            affiliationState,
            specialtyServiceState,
        ],
    );

    return (
        <>
            {isMobile && (
                <ProviderFilterMobile
                    {...props}
                    accordionItems={accordionItems}
                    sortValue={getCurrentCriterion()[0]}
                    sortCriteria={sortCriteria}
                    handleSort={handleSort}
                    distance={distance}
                    setDistance={setDistance}
                    disabledDistanceOptions={disabledDistanceOptions}
                    handleSingleFilter={handleSingleFilter}
                    handleResetAll={handleFilterResetAll}
                    disableNextAvailableSort={disableNextAvailableSort}
                />
            )}
            {!isMobile && (
                <ProviderFilterDesktop
                    {...props}
                    accordionItems={accordionItems}
                    sortValue={getCurrentCriterion()[0]}
                    sortCriteria={sortCriteria}
                    distance={distance}
                    setDistance={setDistance}
                    disabledDistanceOptions={disabledDistanceOptions}
                    handleSort={handleSort}
                    handleSingleFilter={handleSingleFilter}
                    handleResetAll={handleFilterResetAll}
                    disableNextAvailableSort={disableNextAvailableSort}
                />
            )}
        </>
    );
};

export default ProviderFilter;
