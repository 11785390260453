import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import sanitize from 'sanitize-html';

import { Checkbox } from '@/design-system/atoms/Forms/Checkbox';
import { RadioGroup } from '@/design-system/atoms/Forms/RadioGroup';
import { Radio } from '@/design-system/atoms/Forms/RadioGroup/Radio/Radio';
import { SearchableCheckbox } from '@/design-system/atoms/Forms/SearchableCheckbox';
import { SearchableRadio } from '@/design-system/atoms/Forms/SearchableRadio';
import { LazyIconSearch } from '@/design-system/atoms/Icons/IconSearch/Lazy';
import { arrayNotEmpty } from '@/utils/arrayNotEmpty';

import { useProviderSearchContext } from '../../../contexts/ProviderSearchContext';
import styles from '../ProviderFilter.module.scss';

export const ProviderFacetRoot = ({
    urlParamName,
    variant,
    title = 'Filters apply upon selection',
    controller,
    analytics,
    fixedOption,
}) => {
    const { handleSingleFilter, handleMultipleFilters, setCoveoAnalytics } =
        useProviderSearchContext();

    const [state, setState] = React.useState();

    React.useEffect(() => {
        const unsubscribe = controller.subscribe(() => {
            let newValues = null;
            // check if controller.state.values has values with not idle state at the top;
            if (
                arrayNotEmpty(controller?.state?.values) &&
                controller.state.values[0].state !== 'idle'
            ) {
                // Sort the values alphabetically
                newValues = [...controller.state.values].sort((a, b) =>
                    a.value.localeCompare(b.value),
                );
            }
            const newState = {
                ...controller.state,
                ...(newValues ? { values: newValues } : {}),
            };
            setState(newState);
        });
        return () => {
            // Unsubscribe from the facet controller state
            unsubscribe();
        };
    }, [controller]);

    const handleSingleSelect = React.useCallback(
        (newValue) => {
            if (!newValue) {
                setCoveoAnalytics('facetClearAll');

                handleSingleFilter({ [urlParamName]: '' }, { ...analytics, actionLabel: 'All' });
                return;
            }

            const facetValue = state?.values?.find(({ value }) => value === newValue);
            if (!facetValue) {
                console.error('ProviderFacetRoot - invalid facetValue');
                return;
            }

            setCoveoAnalytics('facetSelect', { facetId: urlParamName, facetValue: facetValue });

            handleSingleFilter(
                {
                    [urlParamName]: `${facetValue.value};${facetValue.numberOfResults}`,
                },
                { ...analytics, actionLabel: newValue },
            );
        },
        [state?.values, handleSingleFilter, urlParamName, analytics, setCoveoAnalytics],
    );

    const handleToggleSelect = React.useCallback(
        (newValue) => {
            const facetValue = state?.values?.find(({ value }) => value === newValue);
            if (!facetValue) {
                console.error('ProviderFacetRoot - invalid facetValue');
                return;
            }

            setCoveoAnalytics(
                controller.isValueSelected(facetValue) ? 'facetDeselect' : 'facetSelect',
                { facetId: urlParamName, facetValue: facetValue },
            );

            handleMultipleFilters(
                {
                    [urlParamName]: `${facetValue.value};${facetValue.numberOfResults}`,
                },
                { ...analytics, actionLabel: newValue },
            );
        },
        [
            handleMultipleFilters,
            state?.values,
            urlParamName,
            analytics,
            controller,
            setCoveoAnalytics,
        ],
    );

    const [radioValue, radioItems] = React.useMemo(() => {
        if (variant !== 'radio' && variant !== 'searchable-radio') {
            return [];
        }

        let value = '';
        const items =
            state?.values?.map((v) => {
                if (controller.isValueSelected(v)) {
                    value = v.value;
                }

                return {
                    label: <span dangerouslySetInnerHTML={{ __html: sanitize(v.value) }} />,
                    value: v.value,
                };
            }) ?? [];

        return [value, items];
    }, [variant, state?.values, controller]);

    const checkboxItems = React.useMemo(() => {
        if (variant !== 'searchable-checkbox' && variant !== 'checkbox') {
            return [];
        }

        return (
            state?.values?.map((v) => ({
                label: <span dangerouslySetInnerHTML={{ __html: sanitize(v.value) }} />,
                value: v.value,
                isSelected: controller.isValueSelected(v),
                onChange: () => handleToggleSelect(v.value),
            })) ?? []
        );
    }, [variant, state?.values, controller, handleToggleSelect]);

    if (variant == null || controller == null) {
        console.error('ProviderFacetRoot: variant and controller is required');
        return null;
    }

    if (variant === 'radio') {
        return (
            <fieldset className={styles[`provider-filter__fieldset`]}>
                <legend className={classNames(styles[`provider-filter__legend`], 'type-t3')}>
                    {title}
                </legend>
                <RadioGroup value={radioValue} onChange={handleSingleSelect}>
                    <Radio value="" label="All" />
                    {radioItems.map(({ value, label }) => (
                        <Radio key={value} value={value} label={label} />
                    ))}
                </RadioGroup>
            </fieldset>
        );
    }

    if (variant === 'searchable-radio') {
        return (
            <SearchableRadio
                text={title}
                label="Search"
                iconComponent={LazyIconSearch}
                items={radioItems}
                isScrollable={radioItems.length > 8}
                selectedOption={radioValue}
                onRadioChange={handleSingleSelect}
                fixedOption={fixedOption}
                onTextFieldKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                }}
            />
        );
    }

    if (variant === 'checkbox') {
        return (
            <fieldset className={styles[`provider-filter__fieldset`]}>
                <legend className={classNames(styles[`provider-filter__legend`], 'type-t3')}>
                    {title}
                </legend>
                <ul className={classNames(styles['provider-filter__checkbox-group'])}>
                    {checkboxItems.map((item) => (
                        <li key={item.value}>
                            <Checkbox onChange={handleSingleSelect} {...item} />
                        </li>
                    ))}
                </ul>
            </fieldset>
        );
    }

    if (variant === 'searchable-checkbox') {
        return (
            <SearchableCheckbox
                text={title}
                label="Search"
                iconComponent={LazyIconSearch}
                items={checkboxItems}
                isScrollable={checkboxItems.length > 8}
                fixedOption={fixedOption}
                onTextFieldKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                }}
            />
        );
    }
};

export const ProviderFacetBadge = ({ fieldName }) => {
    const {
        coveo: {
            controller: { breadcrumbManager },
        },
    } = useProviderSearchContext();

    const [state, setState] = React.useState();

    React.useEffect(() => {
        return breadcrumbManager.subscribe(() => setState(breadcrumbManager.state));
    }, [breadcrumbManager]);

    if (fieldName == null) {
        console.error('`ProviderFacetBadge: fieldName is required');
        return null;
    }

    const total = state?.facetBreadcrumbs.find((fb) => fb.field === fieldName)?.values?.length ?? 0;

    if (total === 0) {
        return null;
    }

    const formattedTotal = total > 99 ? '99+' : total;

    return (
        <div className={classNames(styles[`provider-filter__indicator`], 'type-t4')}>
            {formattedTotal}
        </div>
    );
};

ProviderFacetRoot.propTypes = {
    urlParamName: PropTypes.string,
    variant: PropTypes.oneOf(['radio', 'searchable-radio', 'checkbox', 'searchable-checkbox']),
    title: PropTypes.string,
    controller: PropTypes.object,
    analytics: PropTypes.object,
    fixedOption: PropTypes.string,
};

ProviderFacetBadge.propTypes = {
    fieldName: PropTypes.string,
};

export const ProviderFacet = {
    Root: ProviderFacetRoot,
    Badge: ProviderFacetBadge,
};
