import * as customPropTypes from 'custom-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import { Item } from 'react-stately';

import { CustomMultiSelect } from './components/CustomMultiSelect';

const MultiSelect = React.forwardRef((props, ref) => {
    const {
        label,
        searchboxLabel = 'Search',
        name,
        items = [],
        defaultSelectedKeys,
        selectedKeys,
        onSelectionChange,
        onSelectionItemsChange,
        variant = MultiSelect.VARIANT.BORDER,
        openStateIcon,
        closeStateIcon,
        analytics,
        singleSelectEnabled,
    } = props;

    const handleSelectionChange = React.useCallback(
        (keys) => {
            onSelectionChange?.(keys);
            onSelectionItemsChange?.(items.filter((item) => keys?.has(item.value)) ?? null);
        },
        [onSelectionChange, onSelectionItemsChange, items],
    );

    const selectItems = React.useMemo(() => {
        return items.map((item) => (
            <Item key={item.value ?? item.label} textValue={item.label} {...item}></Item>
        ));
    }, [items]);

    return (
        <CustomMultiSelect
            ref={ref}
            {...props}
            label={label}
            searchboxLabel={searchboxLabel}
            name={name}
            items={items}
            defaultSelectedKeys={defaultSelectedKeys}
            selectedKeys={selectedKeys}
            onSelectionChange={handleSelectionChange}
            variant={variant}
            openStateIcon={openStateIcon}
            closeStateIcon={closeStateIcon}
            analytics={analytics}
            singleSelectEnabled={singleSelectEnabled}
        >
            {selectItems}
        </CustomMultiSelect>
    );
});
MultiSelect.VARIANT = {
    BORDER: 'border',
    BOTTOM_BORDER: 'bottom-border',
    NO_BORDER: 'no-border',
};

MultiSelect.propTypes = {
    /**
     * visible label
     */
    label: PropTypes.string,
    /**
     * form control name
     */
    name: PropTypes.string,
    /**
     * List of items
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    /**
     * Default selected keys
     */
    defaultSelectedKeys: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    /**
     * selected keys for controlled component, separated by comma
     */
    selectedkeys: PropTypes.arrayOf(PropTypes.string),
    /**
     * This function is triggered every time that the selected keys change
     */
    onSelectionChange: PropTypes.func,
    /**
     * This function is triggered every time that the selected ITEMs change
     */
    onSelectionItemsChange: PropTypes.func,
    /**
     * Icon displayed when the multiselect dropdown is expanded
     */
    openStateIcon: PropTypes.node,
    /**
     * Icon displayed when the multiselect dropdown is collapsed
     */
    closeStateIcon: PropTypes.node,
    /**
     * analytics object
     */
    analytics: customPropTypes.analyticsPropType,
    /**
     * Whether it is a single select
     */
    singleSelectEnabled: PropTypes.bool,
};

export default MultiSelect;
