import React from 'react';

const ProviderStateContext = React.createContext();
const ProviderSetStateContext = React.createContext();

export const ProviderStateContextProvider = ({ children, initialValue }) => {
    const [state, setState] = React.useState(initialValue);

    return (
        <ProviderSetStateContext.Provider value={setState}>
            <ProviderStateContext.Provider value={state}>{children}</ProviderStateContext.Provider>
        </ProviderSetStateContext.Provider>
    );
};

export const useProviderSetStateContext = () => {
    const context = React.useContext(ProviderSetStateContext);

    if (context == null) {
        throw new Error(
            `'useProviderSetStateContext' needs to be within 'ProviderSetStateContext'`,
        );
    }

    return context;
};

export const useProviderStateContext = () => {
    const context = React.useContext(ProviderStateContext);

    if (context == null) {
        throw new Error(`'useProviderStateContext' needs to be within 'ProviderStateContext'`);
    }

    return context;
};
