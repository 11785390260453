import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion } from '@/design-system/atoms/Accordion';
import { Button } from '@/design-system/atoms/Button';
import { RadioGroup } from '@/design-system/atoms/Forms/RadioGroup';
import { Radio } from '@/design-system/atoms/Forms/RadioGroup/Radio/Radio';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { LazyIconXCircle } from '@/design-system/atoms/Icons/IconXCircle/Lazy';
import { useSearchParams } from '@/hooks/useSearchParams';

import { useProviderSearchContext } from '../../../contexts/ProviderSearchContext';
import { useProviderUIContext } from '../../../contexts/ProviderUIContext';
import styles from '../ProviderFilter.module.scss';

const ProviderFilterDesktop = ({
    accordionItems,
    sortValue,
    sortCriteria,
    handleSort,
    distance,
    setDistance,
    disabledDistanceOptions,
    handleSingleFilter,
    handleResetAll,
    disableNextAvailableSort,
}) => {
    const { resetAllLabel, resetAllAriaLabel } = useProviderUIContext();
    const searchParams = useSearchParams();

    const {
        coveo: {
            controller: { breadcrumbManager },
        },
        handleMultipleFilters,
        setCoveoAnalytics,
    } = useProviderSearchContext();

    const [state, setState] = useState();
    useEffect(() => {
        return breadcrumbManager.subscribe(() => setState(breadcrumbManager.state));
    }, [breadcrumbManager]);

    const analyticsObj = useMemo(
        () => ({
            componentName: 'Filter Sort Panel',
            componentTitle: 'filter_panel',
            allFilters: searchParams,
            interactionType: 'filter_reset',
        }),
        [searchParams],
    );

    const filteredSortCriteria = useMemo(() => {
        return sortCriteria
            .map(([name]) => {
                if (
                    (name === 'Distance' && disabledDistanceOptions) ||
                    (name === 'Next Available' && disableNextAvailableSort)
                ) {
                    return null;
                }
                return name;
            })
            .filter(Boolean);
    }, [sortCriteria, disabledDistanceOptions, disableNextAvailableSort]);

    /**
     * Reset a single filter
     * @param {string} facetId - The facet id to reset
     * @param {string} value - The value to reset
     * @returns {void}
     */
    const handleResetFilter = useCallback(
        (facetId, facetValue) => {
            if (!facetId || !facetValue) {
                console.error('ProviderFacetRoot - invalid facetValue');
                return;
            }

            setCoveoAnalytics('facetBreadcrumb', { facetId: facetId, facetValue: facetValue });

            handleMultipleFilters(
                {
                    [facetId]: `${facetValue.value};${facetValue.numberOfResults}`,
                },
                {
                    ...analyticsObj,
                    actionLabel: facetValue.value,
                },
            );
        },
        [handleMultipleFilters, analyticsObj, setCoveoAnalytics],
    );

    const handleDistanceChange = useCallback(
        (value) => {
            setDistance(value);

            setCoveoAnalytics(
                value ? 'staticFilterSelect' : 'staticFilterClearAll',
                value
                    ? {
                          staticFilterId: 'distance',
                          staticFilterValue: {
                              caption: `Within ${value} miles`,
                              expression: `distance=${value}`,
                          },
                      }
                    : { staticFilterId: 'distance' },
            );

            handleSingleFilter(
                { distance: value },
                {
                    componentTitle: 'Distance',
                    actionLabel: value ? `Within ${value} miles` : 'Any',
                },
            );
        },
        [handleSingleFilter, setDistance, setCoveoAnalytics],
    );

    return (
        <form className={styles[`provider-filter`]}>
            {(state?.hasBreadcrumbs || distance) && (
                <div className={styles[`provider-filter__active-filters`]}>
                    <Button
                        buttonStyle={Button.STYLE.TEXT}
                        className={styles[`provider-filter__active-filters--reset`]}
                        type="button"
                        iconComponent={LazyIconXCircle}
                        iconRight
                        label={resetAllLabel}
                        ariaLabel={resetAllAriaLabel}
                        onClick={handleResetAll}
                        analytics={analyticsObj}
                    />
                    {state?.hasBreadcrumbs && (
                        <div className={styles['provider-filter__active-filters--list']}>
                            {state?.facetBreadcrumbs?.flatMap((filter) =>
                                filter.values
                                    .filter((item) => item.value.state === 'selected')
                                    .map((item) => (
                                        <Button
                                            key={item.value.value}
                                            label={item.value.value}
                                            ariaLabel={`Remove ${item.value.value} filter`}
                                            onClick={() =>
                                                handleResetFilter(filter.facetId, item.value)
                                            }
                                            buttonStyle={Button.STYLE.FILTER}
                                            iconComponent={LazyIconX}
                                            iconSize="medium"
                                            type="button"
                                        />
                                    )),
                            )}
                        </div>
                    )}
                </div>
            )}

            <fieldset
                className={classNames(
                    styles[`provider-filter__fieldset`],
                    styles[`provider-filter__wrapper`],
                )}
            >
                <legend className={classNames(styles[`provider-filter__legend`], 'type-t3')}>
                    Filters apply upon selection.
                </legend>
                {filteredSortCriteria.length > 1 && (
                    <fieldset className={styles[`provider-filter__fieldset`]}>
                        <legend
                            className={classNames(
                                styles[`provider-filter__group-legend`],
                                'type-e1',
                            )}
                        >
                            Sort By:
                        </legend>
                        <RadioGroup
                            className={styles[`provider-filter__radio-group`]}
                            value={sortValue}
                            onChange={handleSort}
                        >
                            {filteredSortCriteria.map((name) => (
                                <Radio key={name} value={name} label={name} />
                            ))}
                        </RadioGroup>
                    </fieldset>
                )}
                <fieldset className={styles[`provider-filter__fieldset`]}>
                    <legend
                        className={classNames(
                            styles[`provider-filter__group-legend`],
                            styles[`provider-filter__container`],
                            'type-e1',
                        )}
                    >
                        Filter By:
                    </legend>
                    {!disabledDistanceOptions && (
                        <div className={styles[`provider-filter__distance-container`]}>
                            <RadioGroup
                                label="Distance"
                                labelClass={styles[`provider-filter__radio-group-label`]}
                                value={distance}
                                onChange={(value) => handleDistanceChange(value)}
                            >
                                <Radio value="" label="Any" />

                                <>
                                    <Radio value="2" label="Within 2 miles" />
                                    <Radio value="5" label="Within 5 miles" />
                                    <Radio value="10" label="Within 10 miles" />
                                    <Radio value="20" label="Within 20 miles" />
                                </>
                            </RadioGroup>
                        </div>
                    )}
                </fieldset>
                <Accordion
                    type={'multiple'}
                    className={styles[`provider-filter__accordion`]}
                    accordionItems={accordionItems}
                    noScrollLogic
                />
            </fieldset>
        </form>
    );
};

ProviderFilterDesktop.propTypes = {
    accordionItems: PropTypes.array,
    sortValue: PropTypes.string,
    sortCriteria: PropTypes.array,
    handleSort: PropTypes.func,
    distance: PropTypes.string,
    setDistance: PropTypes.func,
    disabledDistanceOptions: PropTypes.bool,
    handleSingleFilter: PropTypes.func,
    handleResetAll: PropTypes.func,
    disableNextAvailableSort: PropTypes.bool,
};

export default ProviderFilterDesktop;
