import React from 'react';

const ProviderUIContext = React.createContext({});

export const ProviderUIContextProvider = ({ children, value }) => {
    return <ProviderUIContext.Provider value={value}>{children}</ProviderUIContext.Provider>;
};

export const useProviderUIContext = () => {
    const context = React.useContext(ProviderUIContext);

    if (context == null) {
        throw new Error(`'useProviderUIContext' needs to be within 'ProviderUIContext'`);
    }

    return context;
};
