import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

import { GeolocationProvider } from '@/contexts/GeolocationContext';
import * as customPropTypes from '@/custom-prop-types';
import { Image } from '@/design-system/atoms/Image';
import { GoogleMapsLoader } from '@/design-system/helpers/GoogleMapsLoader';
import { useGenericLists } from '@/hooks/useGenericLists';

import { ProviderHero } from './components/ProviderHero';
import ProviderMain from './components/ProviderMain/ProviderMain';
import { ProviderFilterContextProvider } from './contexts/ProviderFilterContext';
import { ProviderSearchProvider } from './contexts/ProviderSearchContext';
import { ProviderStateContextProvider } from './contexts/ProviderStateContext';
import { ProviderUIContextProvider } from './contexts/ProviderUIContext';
import styles from './ProviderSearch.module.scss';
/**
 * The Provider Search Page is the main search page for finding locations.
 */
const ProviderSearch = ({
    id,
    state,
    title,
    searchLabel,
    searchErrorMessage,
    zipLabel,
    zipErrorMessage,
    breadcrumbs,
    typeAheadFixedItem,
    enableTypeahead,
    media,
    image,
    illustration,
    hideMobileIllustration,
    coveo,
    noResultsComponent,
    initialChildren,
    resultsChildren,
    errorComponent,
    patientTypeLabel,
    patientTypeAriaLabel,
    filterLabel,
    filterAriaLabel,
    resetAllLabel,
    resetAllAriaLabel,
    seeResultsLabel,
    seeResultsAriaLabel,
    googleMaps,
    schedulingOverrideLabels,
    patientTypeId,
    newPatientAppointmentIds,
    insuranceLabel,
    insurancePlans,
    notAcceptingPatientsText,
    existingPatientText,
    noOnlineSchedulingText,
    availabilityLabel,
    disableCalendar,
    disableNextAvailableSort,
}) => {
    const { data } = useGenericLists('provider-affiliations-badges');
    const affiliationBadgeDictionary = useMemo(() => data, [data]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);
    return (
        <GoogleMapsLoader apiKey={googleMaps?.apiKey}>
            <GeolocationProvider>
                <ProviderUIContextProvider
                    value={{
                        title,
                        searchLabel,
                        searchErrorMessage,
                        zipLabel,
                        zipErrorMessage,
                        patientTypeLabel,
                        patientTypeAriaLabel,
                        filterLabel,
                        filterAriaLabel,
                        resetAllLabel,
                        resetAllAriaLabel,
                        seeResultsLabel,
                        seeResultsAriaLabel,
                        googleMaps,
                        insuranceLabel,
                        notAcceptingPatientsText,
                        existingPatientText,
                        noOnlineSchedulingText,
                        availabilityLabel,
                    }}
                >
                    <ProviderStateContextProvider initialValue="default">
                        <ProviderSearchProvider
                            id={id}
                            coveo={coveo}
                            noResultsComponent={noResultsComponent}
                            errorComponent={errorComponent}
                            enableTypeahead={enableTypeahead}
                            disableCalendar={disableCalendar}
                            disableNextAvailableSort={disableNextAvailableSort}
                        >
                            <ProviderFilterContextProvider>
                                <section className={styles['provider-search']}>
                                    <div className={styles['provider-search__header']}>
                                        <ProviderHero
                                            state={state}
                                            breadcrumbs={breadcrumbs}
                                            typeaheadFixedItem={typeAheadFixedItem}
                                            media={media}
                                            image={image}
                                            illustration={illustration}
                                            hideMobileIllustration={hideMobileIllustration}
                                            googleMaps={googleMaps}
                                            insurancePlans={insurancePlans}
                                        />
                                    </div>
                                    <ProviderMain
                                        patientTypeId={patientTypeId}
                                        analytics={{ componentTitle: title }}
                                        schedulingOverrideLabels={schedulingOverrideLabels}
                                        newPatientAppointmentIds={newPatientAppointmentIds}
                                        initialChildren={initialChildren}
                                        resultsChildren={resultsChildren}
                                        affiliationBadgeDictionary={affiliationBadgeDictionary}
                                    />
                                </section>
                            </ProviderFilterContextProvider>
                        </ProviderSearchProvider>
                    </ProviderStateContextProvider>
                </ProviderUIContextProvider>
            </GeolocationProvider>
        </GoogleMapsLoader>
    );
};

ProviderSearch.defaultProps = {
    patientTypeId: 'patient-type',
    insuranceLabel: 'Insurance',
};

ProviderSearch.propTypes = {
    state: PropTypes.string,
    title: PropTypes.string,
    searchLabel: PropTypes.string,
    searchErrorMessage: PropTypes.string,
    zipLabel: PropTypes.string,
    zipErrorMessage: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            title: PropTypes.string,
            csr: PropTypes.bool,
        }),
    ),
    typeAheadFixedItem: customPropTypes.linkPropType,
    enableTypeahead: PropTypes.bool,
    disableCalendar: PropTypes.bool,
    disableNextAvailableSort: PropTypes.bool,
    media: PropTypes.oneOf(['image', 'illustration']),
    image: PropTypes.shape(Image.propTypes),
    illustration: PropTypes.shape({
        scene: PropTypes.string,
        alignment: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        excludes: PropTypes.arrayOf(PropTypes.string),
        autoplay: PropTypes.bool,
        loop: PropTypes.bool,
    }),
    hideMobileIllustration: PropTypes.bool,
    coveo: PropTypes.shape({
        organizationId: PropTypes.string,
        accessToken: PropTypes.string,
        platformUrl: PropTypes.string,
        renewAccessTokenUrl: PropTypes.string,
    }),
    noResultsComponent: PropTypes.node,
    errorComponent: PropTypes.node,
    patientTypeLabel: PropTypes.string,
    patientTypeAriaLabel: PropTypes.string,
    filterLabel: PropTypes.string,
    filterAriaLabel: PropTypes.string,
    resetAllLabel: PropTypes.string,
    resetAllAriaLabel: PropTypes.string,
    seeResultsLabel: PropTypes.string,
    seeResultsAriaLabel: PropTypes.string,
    schedulingOverrideLabels: PropTypes.shape({
        eyebrow: PropTypes.string,
        description: PropTypes.string,
        newPatientLabel: PropTypes.string,
        existingPatientLabel: PropTypes.string,
        dropdownLabel: PropTypes.string,
    }),
    patientTypeId: PropTypes.string,
    newPatientAppointmentIds: PropTypes.arrayOf(PropTypes.string),
    insuranceLabel: PropTypes.string,
    insurancePlans: PropTypes.arrayOf(PropTypes.string),
};

export default ProviderSearch;
