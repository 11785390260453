import { useFormValidationState } from '@react-stately/form';
import { useMenuTriggerState } from '@react-stately/menu';
import React from 'react';

import { useMultiSelectListState } from './useMultiSelectListState';

export const useMultiSelectState = (props) => {
    const [isFocused, setFocused] = React.useState(false);
    const [focusStrategy, setFocusStrategy] = React.useState(null);
    const triggerState = useMenuTriggerState({ ...props });

    const listState = useMultiSelectListState({
        ...props,
        onSelectionChange: (keys) => {
            if (props.onSelectionChange != null) {
                props.onSelectionChange(keys);
            }

            //triggerState.close();
            validationState.commitValidation();
        },
    });

    let validationState = useFormValidationState({
        ...props,
        value: Array.isArray(listState.selectedKeys)
            ? listState.selectedKeys?.join(',')
            : listState.selectedKeys,
    });

    return {
        ...listState,
        ...triggerState,
        ...validationState,
        focusStrategy,
        close() {
            triggerState.close();
        },
        open(focusStrategy = null) {
            if (listState.collection.size > 0) {
                setFocusStrategy(focusStrategy);
                triggerState.open();
            }
        },
        toggle(focusStrategy = null) {
            if (listState.collection.size > 0) {
                setFocusStrategy(focusStrategy);
                triggerState.toggle(focusStrategy);
            }
        },
        isFocused,
        setFocused,
    };
};
