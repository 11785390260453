/**
 * Summarize an array of objects with "more" text
 * @param {Array} array - Array of objects to summarize
 * @param {Number} limit - Number of items to show before summarizing
 * @param {Object} primaryItem - Primary item to show
 * @returns {String} - Summarized string
 */
export const summarizeArray = (array, limit = 3, primaryItem) => {
    if (!array || !Array.isArray(array) || array.length === 0) {
        return '';
    }

    let renderedItems = [];
    let fitleredArray = array;

    if (primaryItem) {
        renderedItems.push(primaryItem);
        fitleredArray = array.filter((el) => el !== primaryItem);
    }

    const otherItems = fitleredArray.slice(0, primaryItem ? limit - 1 : limit);
    const restItems = fitleredArray.slice(primaryItem ? limit - 1 : limit);

    if (otherItems.length > 0) {
        for (var i = 0; i < otherItems.length; i++) {
            renderedItems.push(otherItems[i]);
        }
    }
    const remainderItems = restItems.length > 0 ? `+ ${restItems.length} more` : null;
    const itemsText = renderedItems.join(', ') + (remainderItems ? ` ${remainderItems}` : '');

    return itemsText;
};
