import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { LazyIconCalendar } from '@/design-system/atoms/Icons/IconCalendar/Lazy';
import { LazyIconPhone } from '@/design-system/atoms/Icons/IconPhone/Lazy';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { AvailabilityCalendar } from '@/design-system/molecules/AvailabilityCalendar';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { tokenReplace } from '@/utils/tokenReplace';

import styles from './AvailabilityCard.module.scss';
const AvailabilityCard = ({
    clickToCallLabel,
    notAcceptingPatientsText = 'Currently not accepting new patients',
    existingPatientText,
    noOnlineSchedulingText,
    availabilityLabel = 'View All Times & Schedule',
    onlineBookingAvailable,
    patientType,
    acceptingNewPatients,
    myChartUrl,
    schedulingCta,
    phoneNumber,
    npi,
    newPatientAppointmentIds,
    analytics,
    disableCalendar,
    departmentIds,
}) => {
    const breakpoint = useBreakpoint();
    const isMobile = !breakpoint?.large;

    const callNumber = phoneNumber === null ? '800-247-9580' : phoneNumber;
    const callLabel = clickToCallLabel || `${callNumber}`;

    const analyticsObj = React.useMemo(() => {
        return {
            ...analytics,
        };
    }, [analytics]);
    return (
        <Theme name={Theme.NAME.TREATMENTS_AND_SERVICES} className={styles[`availability-card`]}>
            {!onlineBookingAvailable && (
                <div className={styles[`availability-card__content`]}>
                    {noOnlineSchedulingText && <RichTextEditor text={noOnlineSchedulingText} />}
                    <Button
                        className={noOnlineSchedulingText && styles[`availability-card__button`]}
                        label={callLabel}
                        iconComponent={LazyIconPhone}
                        href={`tel:${callNumber}`}
                        analytics={{
                            ...analyticsObj,
                            interactionType: 'fad_result_primary_cta',
                        }}
                    />
                </div>
            )}
            {onlineBookingAvailable && patientType === 'existing' && (
                <div className={styles[`availability-card__content`]}>
                    {existingPatientText ? (
                        <RichTextEditor
                            text={tokenReplace(existingPatientText, {
                                MyChart: myChartUrl
                                    ? `<a href="${myChartUrl}" target="_blank">MyChart</a>`
                                    : 'MyChart',
                            })}
                            analytics={{
                                ...analyticsObj,
                                interactionType: 'fad_result_cta',
                            }}
                        />
                    ) : (
                        <div>
                            <Text
                                content="Schedule appointment in "
                                variant={Text.VARIANT.T2}
                                tag={Text.TAG.SPAN}
                                className={styles[`availability-card__text`]}
                            />
                            <Button
                                label="MyChart"
                                buttonStyle={Button.STYLE.TEXT}
                                href={myChartUrl}
                                target={Button.TARGET.BLANK}
                                className={styles[`availability-card__button--text`]}
                                analytics={{
                                    ...analyticsObj,
                                    interactionType: 'fad_result_cta',
                                }}
                            />
                            {!isMobile && <br />}
                            <Text
                                content=" or "
                                variant={Text.VARIANT.T2}
                                className={styles[`availability-card__text`]}
                            />
                        </div>
                    )}
                    <Button
                        className={styles[`availability-card__button`]}
                        label={callLabel}
                        iconComponent={LazyIconPhone}
                        href={`tel:${callNumber}`}
                        analytics={{
                            ...analyticsObj,
                            interactionType: 'fad_result_primary_cta',
                        }}
                    />
                </div>
            )}
            {onlineBookingAvailable && patientType === 'new' && acceptingNewPatients && (
                <div className={styles[`availability-card__availability`]}>
                    {!disableCalendar && npi && (
                        <AvailabilityCalendar
                            npi={npi}
                            newPatientAppointmentIds={newPatientAppointmentIds}
                            analytics={{
                                ...analyticsObj,
                            }}
                            key={npi}
                            departmentIds={departmentIds}
                        />
                    )}
                    <Button
                        label={availabilityLabel}
                        iconComponent={LazyIconCalendar}
                        {...schedulingCta}
                        analytics={{
                            ...analyticsObj,
                            interactionType: 'fad_result_primary_cta',
                        }}
                    />
                </div>
            )}
            {onlineBookingAvailable && patientType === 'new' && !acceptingNewPatients && (
                <div className={styles[`availability-card__content`]}>
                    {notAcceptingPatientsText && (
                        <div>
                            <RichTextEditor text={notAcceptingPatientsText} />
                        </div>
                    )}
                    <Button
                        className={styles[`availability-card__button`]}
                        label={callLabel}
                        iconComponent={LazyIconPhone}
                        href={`tel:${callNumber}`}
                        analytics={{
                            ...analyticsObj,
                            interactionType: 'fad_result_primary_cta',
                        }}
                    />
                </div>
            )}
        </Theme>
    );
};

AvailabilityCard.propTypes = {
    /**
     * Text to display for the "Click to Call" button
     */
    clickToCallLabel: PropTypes.string,
    /**
     * Text overwritten for the "View All Times & Schedule" button
     */
    availabilityLabel: PropTypes.string,
    /**
     * Text to display when the provider is not accepting new patients
     */
    notAcceptingPatientsText: PropTypes.string,
    /**
     * Text to display when the patient is an existing patient
     */
    existingPatientText: PropTypes.string,
    /**
     * Text to display when online booking is not available
     */
    noOnlineSchedulingText: PropTypes.string,
    /**
     * Whether online booking is available
     */
    onlineBookingAvailable: PropTypes.bool,
    /**
     * Type of patient
     */
    patientType: PropTypes.oneOf(['new', 'existing']),
    /**
     * Whether the provider is accepting new patients
     */
    acceptingNewPatients: PropTypes.bool,
    /**
     * Phone number of the provider
     */
    phoneNumber: PropTypes.string,
    /**
     * URL to MyChart
     */
    myChartUrl: PropTypes.string,
    /**
     * schedulingCta
     */
    schedulingCta: PropTypes.shape(Button.propTypes),
    /**
     * provider npi
     */
    npi: PropTypes.string,
    /**
     * first available appointment date
     */
    availabilityDate: PropTypes.string,
    /**
     * Visit type IDs - Authored value
     */
    newPatientAppointmentIds: PropTypes.arrayOf(PropTypes.string),
    /**
     * Analytics object
     */
    analytics: PropTypes.object,
};

export default AvailabilityCard;
