import { useListBox, useOption } from '@react-aria/listbox';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeList } from 'react-window';

import { Checkbox } from '@/design-system/atoms/Forms/Checkbox';
import { TextField } from '@/design-system/atoms/Forms/TextField';
import { LazyIconSearch } from '@/design-system/atoms/Icons/IconSearch/Lazy';
import { composeRefs } from '@/utils/composeRefs';

import styles from './List.module.scss';

export const ListItem = (props) => {
    const { item, state, variant, style } = props;
    const itemRef = useRef(null);
    const { optionProps, isSelected, isFocused, isDisabled } = useOption(
        {
            'aria-label': item['aria-label'],
            key: item.key,
        },
        state,
        itemRef,
    );

    return (
        <li
            {...optionProps}
            ref={itemRef}
            className={classNames(
                styles['list-item'],
                isSelected && styles['list-item--selected'],
                isFocused && styles['list-item--focused'],
                isDisabled && styles['list-item--disabled'],
                variant && styles[`list-item--${variant}`],
            )}
            style={style}
        >
            <div className={styles[`list-item__checkbox-wrapper`]}>
                <Checkbox isSelected={isSelected} isReadOnly {...item.props} />
            </div>
        </li>
    );
};

export const List = forwardRef((props, forwardedRef) => {
    const { state, searchboxLabel, setOptionsSize, triggerRef, ...otherProps } = props;
    const listboxRef = useRef(null);
    const { listBoxProps } = useListBox(otherProps, state, listboxRef);

    const collection = useMemo(() => [...state.collection], [state]);

    const virtualized = useMemo(() => [...state.collection].length > 100, [state]);

    const [keyword, setKeyword] = useState('');
    const [filteredItems, setFilteredItems] = useState(collection);

    const inputRef = useRef(null);

    const Row = memo(({ data, index, style }) => {
        const { items, state } = data;
        const item = items[index];

        return (
            <ListItem
                key={item.key}
                item={item}
                state={state}
                style={style}
                variant={'virtualized'}
            />
        );
    }, areEqual);

    useEffect(() => {
        if (state.isOpen) {
            inputRef.current?.focus();
        } else {
            triggerRef.current?.focus();
        }
    }, [state.isOpen, triggerRef]);

    useEffect(() => {
        if (!keyword) {
            setFilteredItems(collection);
        }
        const filtered = collection?.filter(
            (item) => item.props?.value?.toLowerCase().indexOf(keyword.toLowerCase().trim()) > -1,
        );

        setFilteredItems(filtered);
        setOptionsSize(filtered?.length);
    }, [keyword, collection, setOptionsSize]);

    return (
        <div
            className={classNames(
                styles['list-wrapper'],
                virtualized && styles['list-wrapper--virtualized'],
            )}
        >
            <div className={styles['list-search']}>
                <TextField
                    label={searchboxLabel}
                    iconComponent={LazyIconSearch}
                    value={keyword}
                    onChange={setKeyword}
                    ref={inputRef}
                />
            </div>
            <div
                {...listBoxProps}
                ref={composeRefs(forwardedRef, listboxRef)}
                className={styles['list-container']}
                aria-live="polite"
            >
                {virtualized ? (
                    <AutoSizer disableWidth>
                        {({ height }) => (
                            <FixedSizeList
                                height={height}
                                width="99.8%"
                                itemSize={62}
                                itemCount={filteredItems.length}
                                itemData={{ items: filteredItems, state }}
                                className={styles['list--virtualized']}
                            >
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                ) : (
                    <ul role="none" className={styles['list']}>
                        {filteredItems.map((item) => (
                            <ListItem key={item.key} item={item} state={state} />
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
});
List.propTypes = {
    /**
     * state from `useSelectState` or 'useMultiSelectState'
     */
    state: PropTypes.object,
    /**
     * label for the search box in the dropdown
     */
    searchboxLabel: PropTypes.string,
    /**
     * function to dynamically update the filtered items size
     */
    setOptionsSize: PropTypes.func,
};
